import { ParsedUrlQuery } from "querystring";

import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/utils";

import { colors } from "@/lib/styleTokens";

type Props = {
  href: string;
  target?: "_blank";
  children: React.ReactNode;
  color?: string;
  hoverColor?: string;
  query?: ParsedUrlQuery;
  textDecoration?: "underline" | "none";
  style?: SerializedStyles;
  testid?: string;
  title?: string;
};

export const ExternalLink: React.FC<Props> = ({
  href,
  target,
  children,
  query,
  color = "#3372f1",
  hoverColor = colors.blue800,
  textDecoration = "underline",
  style,
  testid,
  title,
}) => {
  const _css = css`
    text-decoration: ${textDecoration};
    color: ${color};
    ${style}
    &:hover {
      color: ${hoverColor};
    }
  `;

  const url = new URL(href);
  if (query) {
    const params = new URLSearchParams();
    for (const key in query) {
      params.append(key, query[key] as string);
    }
    url.search = params.toString();
  }

  return (
    <a
      data-testid={testid}
      href={url.toString()}
      target={target}
      title={title}
      css={_css}
      rel={target === "_blank" ? "noopener noreferrer" : undefined}>
      {children}
    </a>
  );
};
