import { default as NextLink } from "next/link";
import React from "react";

import { ParsedUrlQuery } from "querystring";

import { SerializedStyles, css } from "@emotion/react";

import { colors } from "@/lib/styleTokens";

type Props = {
  href: string;
  target?: "_blank";
  children: React.ReactNode;
  color?: string;
  hoverColor?: string;
  query?: ParsedUrlQuery;
  textDecoration?: "underline" | "none";
  style?: SerializedStyles | SerializedStyles[];
  testid?: string;
  hoverTextDecoration?: "underline" | "none";
};

export const Link: React.FC<Props> = ({
  href,
  target,
  children,
  query,
  color = "#3372f1",
  hoverColor = colors.blue800,
  textDecoration = "none",
  style,
  testid,
  hoverTextDecoration,
}) => {
  const _css = css`
    text-decoration: ${textDecoration};
    color: ${color};
    ${style}
    &:hover {
      color: ${hoverColor};
      text-decoration: ${hoverTextDecoration};
    }
  `;

  return (
    <NextLink
      data-testid={testid}
      href={{ pathname: href, query }}
      passHref
      target={target}
      css={_css}
      rel={target === "_blank" ? "noopener noreferrer" : undefined}>
      {children}
    </NextLink>
  );
};
