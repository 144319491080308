import { injectable } from "tsyringe";

import { CaptchaResult, CaptchaToken } from "@/lib/domain";
import { NewspicksExpertAuthApi } from "@/lib/driver";
import { CaptchaPort } from "@/lib/port";

@injectable()
export class CaptchaGateway implements CaptchaPort {
  constructor(readonly driver: NewspicksExpertAuthApi) {}

  async get(captchaToken: CaptchaToken): Promise<CaptchaResult> {
    try {
      await this.driver.captcha(captchaToken.value);
      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }
}
