import { injectable } from "tsyringe";

import { MailAddress, Token } from "@/lib/domain";
import { NewspicksExpertApi } from "@/lib/driver";
import { EmailSendError, TokenExpired } from "@/lib/Errors";
import { ContactEmailModificationPort } from "@/lib/port";

@injectable()
export class ContactEmailModificationGateway implements ContactEmailModificationPort {
  constructor(readonly driver: NewspicksExpertApi) {}
  async store(mailAddress: MailAddress): Promise<void> {
    const response = await this.driver.storeContactEmailModification(mailAddress.value);
    if (response.status != 201) {
      throw new EmailSendError("EmailSendError");
    }
  }
  async find(token: Token): Promise<void> {
    const response = await this.driver.findContactEmailModification(token.value);
    if (response.status == 404) {
      throw new TokenExpired("TokenExpired");
    }
  }
}
