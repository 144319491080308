import { FCC } from "./FCC";

export class Careers extends FCC<Career> {}

export class Career {
  constructor(
    readonly companyName: CompanyName,
    readonly corporateNumber: CorporateNumber,
    readonly department: Department,
    readonly position: Position,
    readonly detail: CareerDetail,
    readonly enrollmentPeriod: EnrollmentPeriod,
  ) {}
}

export class Year {
  constructor(readonly value: number) {}
}
export class Month {
  constructor(readonly value: number) {}
}
export class YearMonth {
  constructor(
    readonly year: Year | null,
    readonly month: Month | null,
  ) {}

  static from(year?: number | null, month?: number | null): YearMonth {
    return new YearMonth(year ? new Year(year) : null, month ? new Month(month) : null);
  }
}
export class Enrolled {
  constructor(readonly value: boolean) {}

  static empty() {
    return new Enrolled(false);
  }
}
export class EnrollmentPeriod {
  constructor(
    readonly from: YearMonth,
    readonly to: YearMonth,
    readonly enrolled: Enrolled,
  ) {}
}

export class CompanyName {
  constructor(readonly value: string) {}

  static empty() {
    return new CompanyName("");
  }
}

export class Department {
  constructor(readonly value: string) {}
  static empty(): Department {
    return new Department("");
  }
}
export class Position {
  constructor(readonly value: string) {}

  static empty(): Position {
    return new Position("");
  }
}

export class CareerDetail {
  constructor(readonly value: string) {}

  static empty(): CareerDetail {
    return new CareerDetail("");
  }
}

export class CareerSummary {
  constructor(readonly value: string) {}

  static empty() {
    return new CareerSummary("");
  }
}

export class CorporateNumber {
  constructor(readonly value: string | null) {}

  static empty() {
    return new CorporateNumber(null);
  }
}
