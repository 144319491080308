import { useRouter } from "next/router";
import { ComponentProps, useState, ReactNode, createContext, useContext, useEffect, useCallback, useRef } from "react";

import { ExternalLink } from "@/components/atoms/ExternalLink";
import { Toast, TOAST_SLIDE_DURATION_SEC } from "@/components/molecules/Toast";
import { colors } from "@/lib/styleTokens";

const ToastContext = createContext({} as ReturnType<typeof useProvideToast>);

export const useToast = () => {
  return useContext(ToastContext);
};

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const toast = useProvideToast();

  return (
    <ToastContext.Provider value={toast}>
      {children}
      {toast.display && (
        <Toast type={toast.type} isDisappearing={toast.isDisappearing}>
          {toast.message}
        </Toast>
      )}
    </ToastContext.Provider>
  );
};

type ToastType = ComponentProps<typeof Toast>["type"];

const useProvideToast = () => {
  const [display, setDisplay] = useState(false);
  const [isDisappearing, setIsDisappearing] = useState(false);
  const [message, setMessage] = useState<ReactNode>();
  const [type, setType] = useState<ToastType>("success");

  const router = useRouter();

  useEffect(() => {
    setDisplay(false);
  }, [router.pathname]);

  const showTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const disappearTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (showTimeoutRef.current) {
        clearTimeout(showTimeoutRef.current);
      }
      if (disappearTimeoutRef.current) {
        clearTimeout(disappearTimeoutRef.current);
      }
      setIsDisappearing(false);
    };
  }, []);

  const toast = useCallback((option: { message: ReactNode; type: ToastType; duration?: number }) => {
    const { message, type, duration = 5000 } = option;
    setDisplay(true);
    setIsDisappearing(false);
    setMessage(message);
    setType(type);

    if (showTimeoutRef.current) {
      clearTimeout(showTimeoutRef.current);
    }
    if (disappearTimeoutRef.current) {
      clearTimeout(disappearTimeoutRef.current);
    }

    showTimeoutRef.current = setTimeout(() => {
      setIsDisappearing(true);
      disappearTimeoutRef.current = setTimeout(() => {
        setDisplay(false);
        setIsDisappearing(false);
      }, TOAST_SLIDE_DURATION_SEC * 1000);
    }, duration);
  }, []);

  const defaultReadErrorToast = useCallback(() => {
    toast({
      message: "システムエラーが発生しました。時間をおいて再度お試しください。",
      type: "error",
    });
  }, [toast]);

  const defaultWAFErrorToast = useCallback(() => {
    toast({
      message: <WAFErrorToastMessage />,
      type: "error",
    });
  }, [toast]);

  return {
    display,
    isDisappearing,
    message,
    type,
    toast,
    defaultReadErrorToast,
    defaultWAFErrorToast,
  };
};

const WAFErrorToastMessage = () => (
  <div>
    保存に失敗しました。 使用できない文字列が含まれている可能性があります。
    <ExternalLink
      href="https://newspicks.expert/mypage_waf_followup"
      target="_blank"
      color={colors.white}
      hoverColor={colors.white}
      textDecoration="underline">
      こちら
    </ExternalLink>
    よりご連絡ください。
  </div>
);
