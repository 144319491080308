import React from "react";

import { css } from "@emotion/react";

import { colors } from "@/lib/styleTokens";
import { DataTestId } from "@/types/elements";

type Size = "sm" | "md" | "lg";

type Variant = "default" | "outline" | "text";

const buttonColors = {
  primary: {
    default: colors.blue.dark,
    hover: colors.blue800,
    border: colors.blue.dark,
    disabled: colors.blue200,
  },
  secondary: {
    default: colors.gray.dark,
    hover: colors.gray500,
    border: colors.gray200,
    disabled: colors.gray300,
  },
  tertiary: {},
  danger: {
    default: colors.red600,
    hover: colors.red900,
    border: colors.red600,
    disabled: colors.red200,
  },
};

type Color = keyof typeof buttonColors;

type Props = {
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
  icon?: React.ReactNode;
  color?: Color;
  type?: "submit" | "button";
  disabled?: boolean;
  dataTestId?: DataTestId;
  loading?: boolean;
  form?: string;
  variant?: Variant;
  size?: Size;
  rightIcon?: React.ReactNode;
};

export const Button: React.FC<Props> = ({
  className,
  onClick,
  children,
  icon,
  color = "primary",
  type = "button",
  disabled = false,
  dataTestId = null,
  loading = false,
  form,
  variant = "default",
  size = "lg",
  rightIcon,
}) => {
  const handleClick: React.MouseEventHandler = (e) => {
    e.preventDefault();
    onClick?.();
  };

  return (
    <button
      className={className}
      css={[style, getStyleByVariant(color, variant), getStyleBySize(size)]}
      onClick={onClick ? handleClick : undefined}
      type={type}
      disabled={disabled}
      form={form}
      data-testid={dataTestId}>
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;
          line-height: 1;
          align-items: center;
        `}>
        {loading ? (
          <div data-testid="loading" css={spinner}>
            <div className="dot dot1"></div>
            <div className="dot dot2"></div>
            <div className="dot dot3"></div>
          </div>
        ) : (
          <>
            {icon}
            {children}
            {rightIcon}
          </>
        )}
      </div>
    </button>
  );
};

const style = css`
  padding: 12px 16px;
  border: none;
  font-size: 16px;
  border-radius: 4px;
  color: var(--color-font-button);
  cursor: pointer;
  width: 100%;
  font-weight: 700;
  img {
    filter: brightness(0) invert(1);
  }

  :hover:enabled {
    transition: 0.3s ease background;
  }
  :disabled {
    cursor: not-allowed;
  }
`;

const spinner = css`
  display: flex;
  gap: 5px;

  .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ffffff;
    animation: blink 0.5s infinite;
  }

  .dot1 {
    animation-delay: 0s;
  }

  .dot2 {
    animation-delay: 0.2s;
  }

  .dot3 {
    animation-delay: 0.4s;
  }

  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 0.6;
    }
    40% {
      opacity: 1;
    }
    60% {
      opacity: 0.6;
    }
    80% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.2;
    }
  }
`;

const getStyleBySize = (size: Size) => {
  switch (size) {
    case "sm":
      return css`
        font-size: 14px;
        height: 32px;
        padding: 0px 12px;
      `;
    case "md":
      return css`
        font-size: 14px;
        height: 40px;
        padding: 0px 16px;
      `;
    case "lg":
    default:
      return css`
        height: 48px;
        padding: 0px 16px;
      `;
  }
};

const getStyleByVariant = (color: Color, variant: Variant) => {
  if (color === "tertiary") {
    return css`
      background-color: transparent;
      color: ${colors.gray800};
      border: none;

      :disabled {
        color: ${colors.gray300};
      }

      :hover:enabled {
        background-color: ${colors.gray100};
      }
    `;
  }

  switch (variant) {
    case "outline":
      return css`
        background-color: ${colors.white};
        color: ${buttonColors[color].default};
        border: 1px solid ${buttonColors[color].border};

        :disabled {
          background-color: ${colors.gray100};
          color: ${buttonColors[color].disabled};
          border: 1px solid ${buttonColors[color].disabled};
        }

        :hover:enabled {
          background-color: ${colors.gray50};
        }
      `;
    case "text":
      return css`
        background-color: transparent;
        color: ${buttonColors[color].default};
        border: none;

        :disabled {
          opacity: 50%;
        }

        :hover:enabled {
          color: ${buttonColors[color].hover};
        }
      `;
    case "default":
    default:
      return css`
        background-color: ${buttonColors[color].default};
        color: ${colors.white};
        border: 1px solid ${buttonColors[color].border};

        :disabled {
          opacity: 50%;
        }

        :hover:enabled {
          background-color: ${buttonColors[color].hover};
        }
      `;
  }
};
