export class UnauthorizedError extends Error {
  constructor(message?: string) {
    super(message);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnauthorizedError);
    }
  }
}

export class MailAddressAlreadyUsed extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, MailAddressAlreadyUsed.prototype);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, MailAddressAlreadyUsed);
    }
  }
}

export class ReCaptchaFailure extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, ReCaptchaFailure.prototype);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ReCaptchaFailure);
    }
  }
}

export class PasswordMismatch extends Error {
  name = "PasswordMismatch";
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, PasswordMismatch.prototype);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PasswordMismatch);
    }
  }
}

export class MailAddressInvalid extends Error {
  name = "MailAddressInvalid";
  constructor(message?: string) {
    super(message);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, MailAddressInvalid);
    }
  }
}

export class NoMyPageError extends Error {
  name = "NoMyPageError";
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, NoMyPageError.prototype);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NoMyPageError);
    }
  }
}

export class TokenExpired extends Error {
  name = "TokenExpired";
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, TokenExpired.prototype);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TokenExpired);
    }
  }
}

export class AccountRegistrationError extends Error {
  name = "AccountRegistrationError";
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, AccountRegistrationError.prototype);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AccountRegistrationError);
    }
  }
}

export class EmailSendError extends Error {
  name = "EmailSendError";
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, EmailSendError.prototype);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, EmailSendError);
    }
  }
}

export class ContactEmailUpdateError extends Error {
  name = "ContactEmailUpdateError";
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, ContactEmailUpdateError.prototype);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ContactEmailUpdateError);
    }
  }
}

export class WAFError extends Error {
  name = "WAFError";
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, WAFError.prototype);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, WAFError);
    }
  }
}
