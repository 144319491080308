import axios, { AxiosError, AxiosResponse } from "axios";

import { Configuration } from "@/Configuration";
import { WAFError } from "@/lib/Errors";

const createClient = () => {
  const client = axios.create({
    baseURL: Configuration.api.endpoint,
  });
  client.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      if (error.response?.status == 403) {
        return Promise.reject(new WAFError());
      }
      return Promise.reject(error);
    },
  );
  return client;
};

export const client = createClient();
