export const colors = {
  white: "#ffffff",
  black: "#000000",
  light: "#f4f7fb",
  blue: { light: "#316ad3", dark: "#3272f1" },
  blue50: "#ebf3ff",
  blue100: "#d6e6ff",
  blue200: "#a8caff",
  blue300: "#76b4fa",
  blue400: "#4b9dfa",
  blue500: "#2986ff",
  blue600: "#146af5",
  blue700: "#0040e0",
  blue800: "#0032ca",
  blue900: "#042d94",
  red50: "#fff0f0",
  red100: "#ffdbdb",
  red200: "#ffb8b8",
  red300: "#ff8f8f",
  red400: "#fa7373",
  red500: "#ed5353",
  red600: "#de3535",
  red700: "#d41717",
  red800: "#bd0909",
  red900: "#ab0000",
  green50: "#e8ffe8",
  green100: "#d0f2d0",
  green200: "#99d29e",
  green300: "#7dc87d",
  green400: "#4cb04d",
  green500: "#239e2f",
  green600: "#018a26",
  green700: "#037020",
  green800: "#035e21",
  green900: "#044f20",
  yellow50: "#FEF9EC",
  yellow100: "#FDEDC8",
  yellow200: "#FAD98D",
  yellow300: "#F7C152",
  yellow400: "#F5A623",
  yellow500: "#EF8611",
  yellow600: "#D3640C",
  yellow700: "#AF440E",
  yellow800: "#8F3511",
  yellow900: "#431505",
  gray: { dark: "#222222" },
  gray50: "#f5f5f5",
  gray100: "#e6e6e6",
  gray200: "#c7c7c7",
  gray300: "#a3a3a3",
  gray400: "#808080",
  gray500: "#616161",
  gray600: "#474747",
  gray700: "#333333",
  gray800: "#222222",
  gray900: "#111111",
  navyGray: {
    light: "#7e8c9f",
    dark: "#343b45",
  },
  error: "#de3535",
  alert: "#de3535",
  success: {
    primary: "#0aab50",
    secondary: "#e8ffe8",
  },
  caution: {
    secondary: "#fff0f0",
  },
  text: {
    accent: "#146AF5",
    success: "#018a26",
  },
  info: "#ebf3ff",
} as const;

export const breakpoints = {
  mobile: 480,
  tablet: 1024,
} as const;

export const mq = {
  mobile: `@media (width <= ${breakpoints.mobile}px)`,
  tablet: `@media (${breakpoints.mobile}px < width <= ${breakpoints.tablet}px)`,
} as const;
