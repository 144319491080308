export class Reference {
  constructor(
    readonly careerReference: CareerReference | null,
    readonly briefHistoryReference: BriefHistoryReference | null,
  ) {}
}

export class CareerReference {
  constructor(readonly value: string) {}
}

export class BriefHistoryReference {
  constructor(readonly value: string) {}
}
