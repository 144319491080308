export const PROD_AGREEMENT_QUESTIONS = [
  {
    title: "はじめに",
    text: `
    知見を有するエキスパートの皆様と、その知見を必要とするクライアントをつなぐエキスパートネットワーク事業の特性上、コンプライアンスは非常に重要なものと考えています。

    エキスパートの皆様は、本サービス内でエキスパートとして活動していただく前に、このコンプライアンストレーニングを受講してください（その後も年に１回受講するようにしてください）。
    `,
  },
  {
    title: "副業ルールの確認",
    text: `
    副業に一定の制限を設ける企業・団体様が多数存在しています。企業・団体にご所属の方は、ご登録の前に、自身の所属先の副業に関するルール（就業規則や倫理規程等）を確認し、そもそもエキスパートとしての活動が可能かどうか、活動に際しての留意点を確認してください。
    
    - エキスパートとしての活動が所属先のルールにおける「副業」に該当し、これが禁止されている、という場合には活動しないようにしてください。許可の取得等の手続きが必要な場合には、手続きを実施後にご登録ください。
    - 無報酬や実名非公表であれば常に活動可能とは限りません。所属先のルールは正確に理解する必要があり、疑問がある場合はご自身で所属先によく確認するようにしてください。
    `,
  },
  {
    title: "登録資格",
    text: `
    - 犯罪歴を有している方、業法違反、資格はく奪、業界団体における懲罰その他これに類似するペナルティを受けたことのある方はご登録できません。
    - 過去に規約違反等を理由に当社から登録を抹消された方はご登録できません。
    - 当社事業と競合する事業を営む方・その役職員の方はご登録できません。

    （上記に該当しない場合であっても、当社による審査の結果、登録が認められない、あるいは、事後的に登録が抹消される場合があります。）
    `,
  },
  {
    title: "登録情報の正確性",
    text: `
    ご登録に際し、氏名（※）・生年月日・連絡先・経歴等の正確な情報をご提供いただく必要があります。また、ご登録後、提供情報に変更があった場合には、すみやかに変更申請を行い、最新情報を維持するようお願いいたします。
     
    <MdCautionaryNote>※氏名は実名のご提供をお願いします（ビジネスネームでは登録できません）</MdCautionaryNote>
    
    ミーミルは、皆様からご提供いただいた情報をもとに審査を行い、クライアントへご提案、皆様に回答・対応をお願いしています。クライアントも、ご提供情報が真実であるという前提で、知見獲得の対価を支払っています。
     正確かつ最新の情報でない場合、審査が適切に行われず、不適切なマッチングによるトラブルが生じうることとなりますので、上記のとおり、正確な情報のご提供及び適時更新をお願いいたします。
    `,
  },
  {
    title: "受領した情報は秘密情報として取り扱うこと",
    text: `
    ミーミル又はクライアントから受領した情報については、秘密情報として厳重に取り扱うものとし、第三者に開示・提供しないようにしてください。

    - 「ミーミル又はクライアントから受領した情報」には、クライアントからの相談内容やインタビュー等における開示情報のみならず、クライアントの社名が開示された場合の社名も含まれます。 また、クライアントに関する事項につき、インタビュー等で得た情報を基にしたご自身の推測も、第三者に開示・提供しないようにしてください。
    - 提供してはいけない「第三者」には、ご自身の所属先も含まれます。したがって、受領した情報を所属先の他の役職員に伝えることはもちろん、所属先から貸与を受けているPCに情報を入れることも秘密保持義務違反となりえますので、ご注意ください。
    `,
  },
  {
    title: "秘密情報を提供しないこと",
    text: `
    クライアントに対する回答やアドバイスの際に、エキスパート様が現在あるいは過去の所属先や取引先との間で守秘義務を負っている情報や非公開の企業情報を開示・提供しないようにしてください。

    クライアントに対し有益な情報をご提供されようとするお気持ちは、クライアントにとってもミーミルにとっても、大変に有り難いものです。しかし、そのようなお気持ちが行き過ぎてしまい、秘密情報まで提供してしまった場合には、エキスパート様ご自身が法令違反となったり、所属先から契約違反等を追求される事態となってしまいます。場合によっては、クライアントが予定していた投資等が行えなくなったり、クライアントに対して法令違反の疑いを向けられたりすることもありえます。
    
    クライアントには、エキスパート様に秘密を聞き出そうとすることは行わないようお願いしています。他方で、クライアントは、エキスパート様がお持ちの情報のうち何が秘密情報であるかを厳密に知れる立場にはなく、価値ある知見を得ようとするあまり、深堀って聞いてしまうこともありうるところです。
    
    エキスパート様がお持ちの情報のうち、具体的に何が秘密情報に該当するかは、エキスパート様ご自身において理解されている事項です。クライアントから秘密情報の聞出しになりうる質問がなされた場合には、秘密保持義務の違反にならない範囲でご回答いただくか、「秘密保持の観点からお答えできない」と伝えて回答を回避するようにしてください。

    （万が一、クライアントの質問が執拗な秘密の聞出しとなってしまっており、誠実にお話いただいてもクライアントの態度が是正されない場合には、ご対応を中断いただいて結構です。この場合、ミーミルの事務局までご連絡ください。）
    `,
  },
  {
    title: "何が秘密情報に該当するか",
    text: `
    秘密情報は、公開されていない情報であって、企業等が営業秘密として管理している情報や、契約により守秘義務を負っている情報が該当します。
    一般論として以下に挙げるものは、公開されていなければ、秘密情報に該当する可能性が高いものといえます。

    - 財務情報などの定量情報
    - 現在及び将来のマーケティング・事業戦略
    - 予算・収益予想
    - 価格決定方針・コスト構造
    - 取引先の具体名、取引先の担当者等の個人情報、取引先との取引条件・契約の内容、交渉状況
    - 営業ノウハウ
    - クレーム・紛争の有無・内容
    - 進行中のM＆Aや業務提携の内容、M＆Aや業務提携にかかる将来戦略
    - 製品開発・研究の計画の有無・内容
    - 経営陣、資本関係の変更に関する情報
    - 人事情報
    - （医療従事者において）治験に関する非公開情報、個々の患者に関する情報
    - （フランチャイジーにおいて）フランチャイザーにおける財務情報・事業戦略・その他の秘密情報
    - （代理店・販売店において）製造元における財務情報・事業戦略・その他の秘密情報

    ただし、具体の情報が秘密情報であるか否か、厳密なところは、ミーミルにもクライアントにも分かりません。エキスパートの皆様において適切に確認・判断をしていただくようお願いいたします。
    `,
  },
  {
    title: "競業避止・利益相反の観点について",
    text: `
    エキスパート様が現在あるいは過去の所属先等との間で競業避止義務を負っている場合があります。ご所属先とクライアントとが競業を営んでいる関係にある場合、案件を受任することがエキスパート様において競業避止義務違反となる可能性があります。

    役員については会社法等で会社との間の競業避止義務が定められており、従業員の方であっても就業規則や退職時の誓約書等で会社との間の競業避止義務が定められている場合が多いと認識しています。

    ミーミルにおいてはそのようなマッチングを回避できるよう可能な限り努めておりますが、競業を営んでいるか否かの判断は外部事業者の立場では難しい場合も多くあるところです。
    エキスパート様において、所属先等のルールの有無・内容を慎重にご確認のうえ、違反の可能性のある案件を受任しないようお願いいたします。

    クライアント側の投資や新規取引案件において、その投資・新規取引候補先の企業・団体に所属するエキスパート様が、所属先の企業・団体に関する情報をクライアント側に伝えることも、利益相反等の観点から問題となりえます。このような案件となる可能性があるご依頼も、受任を避けるようにしてください。

    なお、前述のとおり、ご登録情報については最新の情報を維持していただく必要があります。特に転職等による経歴のアップデートが漏れてしまうと、競合性・利益相反の有無のチェックが適切に行われなくなってしまうおそれがありますので、適時アップデートをよろしくお願いいたします。
    `,
  },
  {
    title: "直接取引の禁止について",
    text: `
    案件を遂行するに際し、クライアントに対して、ミーミルを除外する形で直接自己や自己の所属先等に発注するよう持ちかけないようにしてください。

    本サービスはミーミルを通じてエキスパートの知見を提供するサービスですので、自己や自己の所属先等の宣伝広告を前面に展開するような言動もお控えください。

    案件の遂行中に、クライアント側から、ミーミルを除外して直接にクライアントとエキスパート様との間で取引するよう持ちかけられた場合も、直ちにこれに応じないようにしていただき、ミーミルの事務局までご一報ください。
    `,
  },
  {
    title: "投資アドバイス・士業サービスの禁止",
    text: `
    本サービス内では、投資助言業に該当しうる投資アドバイス（証券の格付け又は推奨、証券の価値に関するアドバイスの提供、又は証券への投資、購入、又は売却の妥当性に関するアドバイスの提供を含みます）を行うことはできません。
    また、本サービス内では、弁護士法、司法書士法、行政書士法、社会保険労務士法、税理士法、医師法等、特定の資格を有する者にしか提供できない業務を行うことはできません。


    仮にエキスパート様が投資助言の資格や士業資格を有する場合であっても同様であり、本サービス内ではこれらのアドバイス、業務提供を禁止していますのでご留意ください。`,
  },
  {
    title: "第三者の権利侵害の禁止",
    text: `
    案件を遂行するに際し、第三者の知的財産権等、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害するような行為をしてはなりません。

    たとえば、インタビューやセミナーにご対応いただく際に説明資料・セミナー資料をご準備いただく場合がありますが、同資料において、秘密情報を記載しないようにすることはもちろん、所属先等の著作物を許可なく盛り込んだり、プライバシー・名誉を侵害するような情報を掲載しないようにしてください。
    `,
  },
  {
    title: "剽窃行為の禁止",
    text: `
    インターネット上の記事や、生成AIによって生成された回答をコピー&ペースト等でエキスパート様の回答として提示する行為（剽窃行為）は行わないようにしてください。

    インターネット上の記事や生成AIは、適切に用いることによりご自身の知見の向上等に役立つ場合があり、補助的な利用まで禁止するものではございません。
    しかしながら、このサービスは、エキスパート様ご自身の知見に価値があると考え、これをクライアントにご提供していただくことで成り立っております。ご自身の知見に由来することのない剽窃行為は、このサービスの運営に著しい支障を及ぼしうるものですので、厳に慎んでいただきますようお願い申し上げます。

    なお、エキスパート活動を通じて知り得たクライアントの情報や案件・質問の内容は秘密情報であり、このような情報を生成AIに入力する行為は守秘義務の違反にあたる可能性もありますので、ご注意ください。
    `,
  },
  {
    title: "汚職防止法令等の遵守",
    text: `
    国内外の汚職防止法令やガイドライン（日本の刑法や不正競争防止法のほか、米国の海外腐敗行為防止法や英国の2010年贈収賄防止法を含みます）に違反する案件受諾、活動を行わないようにしてください。違反を疑われるような行為も慎む必要があり、案件に関連して、政府関係者等に金銭その他の有価物を与えたり、与えることを約束するような行為は控えてください。

    自らが公務員等に該当する方は、自身のお立場において適用される法令等や内規のルールを確認し、これらに違反するおそれのある案件は受任しないようにしてください。
    特に、自らが担当している、担当する可能性のある領域についての案件には注意が必要ですし、異動があった場合、かつて担当していた領域であっても、異動後一定期間は活動に制限が設けられている場合もあります（国家公務員について同倫理規程９条１項、２条２項等参照）。
    `,
  },
];
