import { injectable } from "tsyringe";

import { Referral } from "@/lib/domain";
import { Location } from "@/lib/driver";
import { ReferralPort } from "@/lib/port";

@injectable()
export class ReferralGateway implements ReferralPort {
  constructor(readonly location: Location) {}

  find(): Referral | null {
    const tokey = this.location.findQueryParameter("tokey");
    const suid = this.location.findQueryParameter("suid");
    const code = this.location.findQueryParameter("code");
    if (tokey == null && suid == null && code == null) {
      return null;
    } else {
      return new Referral(tokey, suid, code);
    }
  }
}
