import { css } from "@emotion/react";

import { mq } from "@/lib/styleTokens";

type Props = { title?: string; explanation?: React.ReactNode };

export const PageHeading: React.FC<Props> = ({ title, explanation }) => {
  return (
    <div
      css={css`
        font-weight: bold;
        color: var(--color-font);
        text-align: left;
      `}>
      <h1
        css={css`
          font-size: 32px;
          line-height: 1.5;
        `}
        data-testid="page-heading-title">
        {title}
      </h1>
      {explanation && (
        <div
          css={css`
            font-size: 16px;
            line-height: 1em;
            margin-top: 16px;
            font-weight: normal;
            ${mq.mobile} {
              line-height: 1.5;
            }
          `}
          data-testid="page-heading-explanation">
          {explanation}
        </div>
      )}
    </div>
  );
};
