export class Birthday {
  constructor(readonly value: Date) {}

  getYear(): number {
    return this.value.getUTCFullYear();
  }

  getMonth(): number {
    return this.value.getUTCMonth() + 1;
  }

  getDay(): number {
    return this.value.getUTCDate();
  }

  toISODateString(): string {
    return this.value.toISOString().substring(0, 10);
  }

  static from(year: string, month: string, day: string): Birthday | null {
    if (!year || !month || !day) {
      return null;
    }
    return new Birthday(new Date(`${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}T00:00:00.000Z`));
  }
}
