import { css, keyframes } from "@emotion/react";

import { colors } from "@/lib/styleTokens";
import { HEADER_HEIGHT } from "@/ui/layout/Header";

type Props = {
  type: keyof typeof types;
  isDisappearing: boolean;
  children?: React.ReactNode;
};

export const Toast: React.FC<Props> = ({ type, children, isDisappearing }) => {
  return (
    <div
      className="alert"
      css={[
        css`
          position: fixed;
          width: 100%;
          min-height: 56px;
          padding: 16px;
          border-radius: 4px;
          top: ${HEADER_HEIGHT};
          left: 0;
          font-size: 16px;
          font-weight: bold;
          color: ${colors.white};
          display: flex;
          align-items: center;
          justify-content: center;
          ${getAnimationStyle(isDisappearing)}
        `,
        types[type],
      ]}>
      {children}
    </div>
  );
};

const types = {
  success: css`
    background-color: ${colors.success.primary};
  `,
  error: css`
    background-color: ${colors.alert};
  `,
};

export const TOAST_SLIDE_DURATION_SEC = 0.3;
const easingFunction = "cubic-bezier(0.33, 1, 0.68, 1)";

const slideAnimation = ({ isAppearing }: { isAppearing: boolean }) => keyframes`
  from {
    transform: translateY(${isAppearing ? "-100%" : "0"});
    opacity: ${isAppearing ? "0" : "1"};
  }
  to {
    transform: translateY(${isAppearing ? "0" : "-100%"});
    opacity: ${isAppearing ? "1" : "0"};
  }
`;

const getAnimationStyle = (isDisappearing: boolean) => {
  if (isDisappearing) {
    return css`
      animation: ${slideAnimation({ isAppearing: false })} ${TOAST_SLIDE_DURATION_SEC}s ${easingFunction};
      animation-fill-mode: forwards;
    `;
  } else {
    return css`
      animation: ${slideAnimation({ isAppearing: true })} ${TOAST_SLIDE_DURATION_SEC}s ${easingFunction};
    `;
  }
};
