export class MailAddressUpdateStatus {
  protected constructor(readonly value: MailAddressUpdateStatusValue) {}
  static fromStatusCode(status: number) {
    switch (status) {
      case 200:
        return succeed;
      case 404:
        return expired;
      case 409:
        return conflict;
      default:
        return failed;
    }
  }
}

class Succeed extends MailAddressUpdateStatus {
  constructor() {
    super("succeed");
  }
}

class Failed extends MailAddressUpdateStatus {
  constructor() {
    super("failed");
  }
}

class Conflict extends MailAddressUpdateStatus {
  constructor() {
    super("conflict");
  }
}

class Expired extends MailAddressUpdateStatus {
  constructor() {
    super("expired");
  }
}

export const succeed = new Succeed();
export const failed = new Failed();
export const expired = new Expired();
export const conflict = new Conflict();

export type MailAddressUpdateStatusValue = "succeed" | "failed" | "expired" | "conflict";
