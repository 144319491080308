export class FCC<T> {
  constructor(readonly values: T[]) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  map<U>(callbackfn: (value: T, index: number, array: T[]) => U, thisArg?: any): U[] {
    return this.values.map(callbackfn, thisArg);
  }

  filter(predicate: (value: T, index: number, array: T[]) => unknown): T[] {
    return this.values.filter(predicate);
  }
}
