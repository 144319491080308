import { injectable } from "tsyringe";

@injectable()
export class ResidentRegions {
  getAll(): Array<{ id: string; name: string }> {
    return [
      { id: "japan", name: "日本" },
      { id: "iceland", name: "アイスランド" },
      { id: "ireland", name: "アイルランド" },
      { id: "azerbaijan", name: "アゼルバイジャン" },
      { id: "afghanistan", name: "アフガニスタン" },
      { id: "us", name: "アメリカ合衆国" },
      { id: "united_arab_emirates", name: "アラブ首長国連邦" },
      { id: "algeria", name: "アルジェリア" },
      { id: "argentina", name: "アルゼンチン" },
      { id: "albania", name: "アルバニア" },
      { id: "armenia", name: "アルメニア" },
      { id: "angola", name: "アンゴラ" },
      { id: "antigua_and_barbuda", name: "アンティグア・バーブーダ" },
      { id: "andorra", name: "アンドラ" },
      { id: "yemen", name: "イエメン" },
      { id: "uk", name: "イギリス" },
      { id: "israel", name: "イスラエル" },
      { id: "italy", name: "イタリア" },
      { id: "iraq", name: "イラク" },
      { id: "iran", name: "イラン" },
      { id: "india", name: "インド" },
      { id: "indonesia", name: "インドネシア" },
      { id: "uganda", name: "ウガンダ" },
      { id: "ukraine", name: "ウクライナ" },
      { id: "uzbekistan", name: "ウズベキスタン" },
      { id: "uruguay", name: "ウルグアイ" },
      { id: "ecuador", name: "エクアドル" },
      { id: "egypt", name: "エジプト" },
      { id: "estonia", name: "エストニア" },
      { id: "eswatini", name: "エスワティニ" },
      { id: "ethiopia", name: "エチオピア" },
      { id: "eritrea", name: "エリトリア" },
      { id: "el_salvador", name: "エルサルバドル" },
      { id: "australia", name: "オーストラリア" },
      { id: "austria", name: "オーストリア" },
      { id: "oman", name: "オマーン" },
      { id: "netherlands", name: "オランダ" },
      { id: "ghana", name: "ガーナ" },
      { id: "republic_of_cabo_verde", name: "カーボベルデ" },
      { id: "guyana", name: "ガイアナ" },
      { id: "kazakhstan", name: "カザフスタン" },
      { id: "qatar", name: "カタール" },
      { id: "canada", name: "カナダ" },
      { id: "gabon", name: "ガボン" },
      { id: "cameroon", name: "カメルーン" },
      { id: "republic_of_korea", name: "韓国" },
      { id: "gambia", name: "ガンビア" },
      { id: "cambodia", name: "カンボジア" },
      { id: "north_korea", name: "北朝鮮" },
      { id: "north_macedonia", name: "北マケドニア" },
      { id: "guinea", name: "ギニア" },
      { id: "guinea-bissau", name: "ギニアビサウ" },
      { id: "cyprus", name: "キプロス" },
      { id: "cuba", name: "キューバ" },
      { id: "greece", name: "ギリシャ" },
      { id: "kiribati", name: "キリバス" },
      { id: "kyrgyz_republic", name: "キルギス" },
      { id: "guatemala", name: "グアテマラ" },
      { id: "kuwait", name: "クウェート" },
      { id: "cook", name: "クック諸島" },
      { id: "grenada", name: "グレナダ" },
      { id: "croatia", name: "クロアチア" },
      { id: "kenya", name: "ケニア" },
      { id: "cote_d_ivoire", name: "コートジボワール" },
      { id: "costa_rica", name: "コスタリカ" },
      { id: "kosovo", name: "コソボ" },
      { id: "comoros", name: "コモロ" },
      { id: "colombia", name: "コロンビア" },
      { id: "republic_of_congo", name: "コンゴ共和国" },
      { id: "democratic_republic_of_the_congo", name: "コンゴ民主共和国" },
      { id: "saudi_arabia", name: "サウジアラビア" },
      { id: "samoa", name: "サモア" },
      { id: "sao_tome_and_principe", name: "サントメ・プリンシペ" },
      { id: "zambia", name: "ザンビア" },
      { id: "san_marino", name: "サンマリノ" },
      { id: "sierra_leone", name: "シエラレオネ" },
      { id: "djibouti", name: "ジブチ" },
      { id: "jamaica", name: "ジャマイカ" },
      { id: "georgia", name: "ジョージア" },
      { id: "syria", name: "シリア" },
      { id: "singapore", name: "シンガポール" },
      { id: "zimbabwe", name: "ジンバブエ" },
      { id: "switzerland", name: "スイス" },
      { id: "sweden", name: "スウェーデン" },
      { id: "sudan", name: "スーダン" },
      { id: "spain", name: "スペイン" },
      { id: "suriname", name: "スリナム" },
      { id: "sri_lanka", name: "スリランカ" },
      { id: "slovakia", name: "スロバキア" },
      { id: "slovenia", name: "スロベニア" },
      { id: "seychelles", name: "セーシェル" },
      { id: "equatorial_guinea", name: "赤道ギニア" },
      { id: "senegal", name: "セネガル" },
      { id: "serbia", name: "セルビア" },
      { id: "saint_christopher_and_nevis", name: "セントクリストファー・ネービス" },
      { id: "saint_vincent_and_the_grenadines", name: "セントビンセント・グレナディーン" },
      { id: "saint_lucia", name: "セントルシア" },
      { id: "somalia", name: "ソマリア" },
      { id: "solomon", name: "ソロモン諸島" },
      { id: "thailand", name: "タイ" },
      { id: "taiwan", name: "台湾" },
      { id: "tajikistan", name: "タジキスタン" },
      { id: "tanzania", name: "タンザニア" },
      { id: "czech_republic", name: "チェコ" },
      { id: "chad", name: "チャド" },
      { id: "central_african_republic", name: "中央アフリカ" },
      { id: "china", name: "中国" },
      { id: "tunisia", name: "チュニジア" },
      { id: "chile", name: "チリ" },
      { id: "tuvalu", name: "ツバル" },
      { id: "denmark", name: "デンマーク" },
      { id: "germany", name: "ドイツ" },
      { id: "togo", name: "トーゴ" },
      { id: "dominican_republic", name: "ドミニカ共和国" },
      { id: "dominica", name: "ドミニカ国" },
      { id: "trinidad_and_tobago", name: "トリニダード・トバゴ" },
      { id: "turkmenistan", name: "トルクメニスタン" },
      { id: "turkey", name: "トルコ" },
      { id: "tonga", name: "トンガ" },
      { id: "nigeria", name: "ナイジェリア" },
      { id: "nauru", name: "ナウル" },
      { id: "namibia", name: "ナミビア" },
      { id: "niue", name: "ニウエ" },
      { id: "nicaragua", name: "ニカラグア" },
      { id: "niger", name: "ニジェール" },
      { id: "new_zealand", name: "ニュージーランド" },
      { id: "nepal", name: "ネパール" },
      { id: "norway", name: "ノルウェー" },
      { id: "bahrain", name: "バーレーン" },
      { id: "haiti", name: "ハイチ" },
      { id: "pakistan", name: "パキスタン" },
      { id: "vatican", name: "バチカン" },
      { id: "panama", name: "パナマ" },
      { id: "vanuatu", name: "バヌアツ" },
      { id: "bahamas", name: "バハマ" },
      { id: "papua_new_guinea", name: "パプアニューギニア" },
      { id: "palau", name: "パラオ" },
      { id: "paraguay", name: "パラグアイ" },
      { id: "barbados", name: "バルバドス" },
      { id: "palestine", name: "パレスチナ" },
      { id: "hungary", name: "ハンガリー" },
      { id: "bangladesh", name: "バングラデシュ" },
      { id: "timor-leste", name: "東ティモール" },
      { id: "fiji", name: "フィジー" },
      { id: "philippines", name: "フィリピン" },
      { id: "finland", name: "フィンランド" },
      { id: "bhutan", name: "ブータン" },
      { id: "brazil", name: "ブラジル" },
      { id: "france", name: "フランス" },
      { id: "bulgaria", name: "ブルガリア" },
      { id: "burkina_faso", name: "ブルキナファソ" },
      { id: "brunei", name: "ブルネイ・ダルサラーム" },
      { id: "burundi", name: "ブルンジ" },
      { id: "viet_nam", name: "ベトナム" },
      { id: "benin", name: "ベナン" },
      { id: "venezuela", name: "ベネズエラ" },
      { id: "belarus", name: "ベラルーシ" },
      { id: "belize", name: "ベリーズ" },
      { id: "peru", name: "ペルー" },
      { id: "belgium", name: "ベルギー" },
      { id: "poland", name: "ポーランド" },
      { id: "bosnia_and_herzegovina", name: "ボスニア・ヘルツェゴビナ" },
      { id: "botswana", name: "ボツワナ" },
      { id: "bolivia", name: "ボリビア" },
      { id: "portugal", name: "ポルトガル" },
      { id: "hong_kong", name: "香港" },
      { id: "honduras", name: "ホンジュラス" },
      { id: "marshall", name: "マーシャル諸島" },
      { id: "macau", name: "マカオ" },
      { id: "madagascar", name: "マダガスカル" },
      { id: "malawi", name: "マラウイ" },
      { id: "mali", name: "マリ" },
      { id: "malta", name: "マルタ" },
      { id: "malaysia", name: "マレーシア" },
      { id: "micronesia", name: "ミクロネシア" },
      { id: "republic_of_south_africa", name: "南アフリカ" },
      { id: "south_sudan", name: "南スーダン" },
      { id: "myanmar", name: "ミャンマー" },
      { id: "mexico", name: "メキシコ" },
      { id: "mauritius", name: "モーリシャス" },
      { id: "mauritania", name: "モーリタニア" },
      { id: "mozambique", name: "モザンビーク" },
      { id: "monaco", name: "モナコ" },
      { id: "maldives", name: "モルディブ" },
      { id: "moldova", name: "モルドバ" },
      { id: "morocco", name: "モロッコ" },
      { id: "mongolia", name: "モンゴル" },
      { id: "montenegro", name: "モンテネグロ" },
      { id: "jordan", name: "ヨルダン" },
      { id: "laos", name: "ラオス" },
      { id: "latvia", name: "ラトビア" },
      { id: "lithuania", name: "リトアニア" },
      { id: "libya", name: "リビア" },
      { id: "liechtenstein", name: "リヒテンシュタイン" },
      { id: "liberia", name: "リベリア" },
      { id: "romania", name: "ルーマニア" },
      { id: "luxembourg", name: "ルクセンブルク" },
      { id: "rwanda", name: "ルワンダ" },
      { id: "lesotho", name: "レソト" },
      { id: "lebanon", name: "レバノン" },
      { id: "russia", name: "ロシア" },
    ];
  }
}
