import { injectable } from "tsyringe";

import { ConversionPath } from "@/lib/domain";
import { Location } from "@/lib/driver";
import { ConversionPathPort } from "@/lib/port/ConversionPathPort";

@injectable()
export class ConversionPathGateway implements ConversionPathPort {
  constructor(readonly location: Location) {}

  find(): ConversionPath | null {
    const conversionPath = this.location.findQueryParameter("cp");
    return conversionPath != null ? new ConversionPath(conversionPath) : null;
  }
}
