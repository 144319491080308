import { css, Global } from "@emotion/react";

import { colors } from "@/lib/styleTokens";

export const GlobalStyles = () => <Global styles={[global]} />;

const colorsCss = css`
  :root {
    --color-background: ${colors.gray50};
    --color-background-button: ${colors.blue.dark};
    --color-background-button-secondary: ${colors.white};
    --color-border-input: ${colors.gray100};
    --color-border-base-default: ${colors.gray100};
    --color-background-hover: ${colors.blue.dark};
    --color-font: ${colors.gray.dark};
    --color-font-text: ${colors.navyGray.dark};
    --color-font-label: ${colors.navyGray.dark};
    --color-font-form-label: ${colors.navyGray.light};
    --color-font-subtext: ${colors.navyGray.dark};
    --color-font-placeholder: ${colors.gray300};
    --color-font-button: ${colors.navyGray.dark};
    --color-footer-background: ${colors.white};

    --box-shadow-form: 0px 0px 16px 0px rgba(0, 0, 0, 0.12), 0px 0px 4px 0px rgba(0, 0, 0, 0.24);
    --box-shadow-form-mobile: 0px 16px 20px rgba(0, 77, 181, 0.05);
  }
`;

const global = css`
  ${colorsCss}

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  body,
  html {
    overflow-x: clip;
    :has([data-modal-open="true"]) {
      overflow: clip;
      scrollbar-gutter: stable;
    }

    font-family: "Hiragino Kaku Gothic ProN", "メイリオ", sans-serif;
    line-height: 1.5;
    letter-spacing: 0.03em;
    background: var(--color-background);
    color: var(--color-font);
  }

  input,
  textarea,
  button {
    font-family: inherit;
    font-size: inherit;
  }

  input {
    line-height: 1;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  fieldset {
    padding: 0;
    margin: 0;
    border: 0;
  }
`;
