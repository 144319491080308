import { injectable } from "tsyringe";

import { Industries, Industry, IndustryId, IndustryName } from "@/lib/domain";
import { Industries as IndustriesDriver } from "@/lib/driver";
import { IndustriesPort } from "@/lib/port";

@injectable()
export class IndustriesGateway implements IndustriesPort {
  constructor(readonly driver: IndustriesDriver) {}

  find(): Industries {
    return new Industries(
      this.driver.getAll().map((v) => new Industry(new IndustryId(v.id), new IndustryName(v.name))),
    );
  }
}
