import { injectable } from "tsyringe";

import { Configuration } from "@/Configuration";

@injectable()
export class ReCaptchaTokenGenerator {
  async createToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        grecaptcha.ready(async () => {
          try {
            const token = await grecaptcha.execute(Configuration.reCaptcha.siteKey, { action: "SignUp" });
            resolve(token);
          } catch (e) {
            reject(e);
          }
        });
      } catch (e) {
        reject(e);
      }
    });
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace grecaptcha {
  function ready(cb: () => void): void;
  function execute(key: string, opts: { action: string }): Promise<string>;
}
