import { useRouter } from "next/router";
import { createContext, useCallback, useContext, useEffect, useId, useState } from "react";

type Props = {
  children: React.ReactNode;
};

const LeaveConfirmContext = createContext({} as ReturnType<typeof useProvideLeaveConfirm>);

export const useLeaveConfirm = (isDirty: boolean) => {
  const formId = useId();

  const { registerLeaveConfirm, unregisterLeaveConfirm } = useContext(LeaveConfirmContext);
  useEffect(() => {
    if (isDirty) {
      registerLeaveConfirm(formId);

      return () => {
        unregisterLeaveConfirm(formId);
      };
    }
  }, [formId, isDirty, registerLeaveConfirm, unregisterLeaveConfirm]);
};

export const LeaveConfirmProvider: React.FC<Props> = ({ children }) => {
  const leaveConfirm = useProvideLeaveConfirm();

  return <LeaveConfirmContext.Provider value={leaveConfirm}>{children}</LeaveConfirmContext.Provider>;
};

export const useProvideLeaveConfirm = () => {
  const [editingFormIds, setEditingFormIds] = useState<string[]>([]);

  const registerEditingFormId = useCallback((id: string) => {
    setEditingFormIds((ids) => Array.from(new Set([...ids, id])));
  }, []);

  const unregisterEditingFormId = useCallback((id: string) => {
    setEditingFormIds((ids) => ids.filter((_id) => _id !== id));
  }, []);

  useDisplayLeaveConfirm(editingFormIds.length > 0);

  return {
    registerLeaveConfirm: registerEditingFormId,
    unregisterLeaveConfirm: unregisterEditingFormId,
  };
};

const useDisplayLeaveConfirm = (display = false) => {
  const router = useRouter();
  const [isBrowserBack, setIsBrowserBack] = useState(false);

  useEffect(() => {
    if (!display) return;

    const confirmMessage = "このページを離れますか？保存していない変更は失われます。";

    const handleRouteChangeStart = () => {
      if (!isBrowserBack && !confirm(confirmMessage)) {
        router.events.emit("routeChangeError");
        throw "routeChange aborted.";
      }
    };

    const setBeforePopState = () => {
      router.beforePopState(() => {
        if (!confirm(confirmMessage)) {
          window.history.pushState(null, "", router.asPath);
          return false;
        }

        setIsBrowserBack(true);
        return true;
      });
    };

    const clearBeforePopState = () => {
      router.beforePopState(() => true);
    };

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      // Chrome では event.returnValue に値をセットする必要
      event.returnValue = "";
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    window.addEventListener("beforeunload", handleBeforeUnload);
    setBeforePopState();

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      clearBeforePopState();
    };
  }, [router, isBrowserBack, display]);
};
