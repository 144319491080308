import { TwoChoiceQuestion } from "@/assets/data/complianceTraning/types";

export const PROD_TWO_CHOICE_QUESTIONS: TwoChoiceQuestion[] = [
  {
    title: "次の内容において、Aの対応は適切であると言えるか",
    question: `
    エキスパート登録者であるAは、クライアントからインタビューを受けている最中に、クライアントの顧客が自身の所属企業の競合企業であり、自身の回答が競合企業に提供される可能性があることに気づいた。すでにインタビュー実施中であり、中止するのもクライアントに申し訳ないので、そのままインタビューに応じることにした。

    Ａの対応は適切か否か。
    `,
    correctAnswer: "適切ではない",
    explanation: `
    インタビュー実施中に競業避止義務違反の可能性に気づいた場合には、クライアントにその旨説明し、必要に応じてインタビューを中止するようにしてください。また、ミーミルの事務局までご連絡ください。
    `,
  },
  {
    title: "次の内容において、Aの対応は適切であると言えるか",
    question: `
    エキスパート登録者であるＡは、自身が所属する企業において副業禁止ルールが設けられているか否かは確認していなかったが、いずれにしても無報酬の活動であれば副業禁止ルールに抵触しないことは明らかであると考え、所属企業に確認することなくエキスパートとしての登録および活動を継続することにした。
    
    Ａの対応は適切か否か。
    `,
    correctAnswer: "適切ではない",
    explanation: `
    自身が所属する企業のルールについては必ずご自身で確認するようにしてください。
    無報酬であれば問題ないかどうかは一概にはいえず、ルールに疑義がある場合には疑義を払拭したうえでエキスパートとして活動するようにしてください。
    `,
  },
  {
    title: "次の内容において、Aの行動は適切であると言えるか",
    question: `
    エキスパート登録者であるＡは、クライアントからインタビューを受けたところ、このクライアントが、自身の所属企業の顧客になる可能性があると考え、所属企業の上司にクライアントの社名・担当者名と相談内容を話して、クライアントと商談を設定するようもちかけた。Ａの行動は所属企業の従業員として所属企業の利益になる行動であり、クライアントにとってもメリットのありうる話であると考えた。
    
    Ａの行動は適切か否か。
    `,
    correctAnswer: "適切ではない",
    explanation: `
    クライアントの社名・担当者名と相談内容を第三者に開示・提供することは秘密保持義務違反になります。仮にクライアントの了解を得られたとしても、インタビューを受けたクライアントとの取引を所属企業に持ち掛けて、クライアントと所属企業とでミーミルを除外して直接取引を行った場合には直接取引の禁止に抵触します。
    `,
  },
  {
    title: "次の内容において、Aの考えは適切であると言えるか",
    question: `
    エキスパート登録者であるAは、上場企業であるクライアントに対して、未公表である新製品の展開のためのマーケティング施策について助言した。新製品を展開する予定がある事実は、いわゆるインサイダー情報に該当する可能性があるが、株の取引さえ行わなければ法令違反になるわけではないので、これを友人に話しても問題ないと考えた。
    
    Ａの考えは適切か否か。
    `,
    correctAnswer: "適切ではない",
    explanation: `
    クライアントから提供された新製品を展開する予定があるという情報を第三者に開示・提供することは、秘密保持義務違反になります。
    `,
  },
  {
    title: "次の内容において、Aの考えは適切であると言えるか",
    question: `
    エキスパート登録者であるAは、1か月前にX社を退職し、現在は全く別の業界の会社に所属しているが、X社の競合企業であるクライアントからのインタビュー依頼を受けた。クライアントとしては、同業の会社を退職済みの者であれば競合の観点は問題ないと考えているとのことであった。自身がX社に所属していたのは過去の話なので、今回のインタビューを受けることは当然可能であり、特に制限なく回答しようと考えている。
    
    Aの考えは適切か否か。
    `,
    correctAnswer: "適切ではない",
    explanation: `
    退職後も一定の範囲で競業避止義務を負っている場合がありますので、ご自身でよくご確認のうえで受任の可否を判断してください。
    また、退職後であっても元所属企業の機密情報を競合企業に開示・提供することは、法令違反、契約違反になる可能性がありますので、注意して対応するようにしてください。
    `,
  },
  {
    title: "次の内容において、Aの考えは適切であると言えるか",
    question: `
    エキスパート登録者であるAは、インタビュー中、自社であるX社の製品の価格設定に関する質問を受けた。
    公開されていない情報であるが、開発内容に関する話ではないし、価格は少なくともX社の顧客が取引することで把握できる情報であるので、Aは自社の価格設定について話すことは問題ないと考えた。
    
    Aの考えは適切か否か。
    `,
    correctAnswer: "適切ではない",
    explanation: `
    価格設定内容は公開されていないことから、秘密情報の可能性が高いと考えられます。X社における販売戦略・コスト構造等に関連する話と考えられ、一般論としても慎重に取り扱われるべき情報です。そのため、話すことができません。
    `,
  },
  {
    title: "次の内容において、Aの考えは適切であると言えるか",
    question: `
    エキスパート登録者であるAは、X社に勤めている。インタビューの中でクライアントより、X社のマーケティングにおける効果測定に関して具体的なKPIの中身とその成果について聞きたいという質問を受けた。これらは公開されてはいないものの、X社内では全社員に広く知られている情報であることから、Aとしては秘匿性は高くないと考え、これを回答したいと考えている。
    
    Aの考えは適切か否か。
    `,
    correctAnswer: "適切ではない",
    explanation: `
    自社に関する具体的なKPIの中身やその成果に関しては、公開されていないのであれば、秘密情報の可能性が高いと考えられます。社内で広く知られていることだけで秘密情報でないと考えるのは適切ではなく、慎重な判断を要するところです。
    `,
  },
  {
    title: "次の内容において、Aの考えは適切であると言えるか",
    question: `
    エキスパート登録者であるAは、X社における経理財務部門の責任者である。業界動向をテーマとするインタビューの中で、自社X社の予算や収益予想についての質問を受けた。Aは、それらは秘密情報なのでご回答できないと述べつつ、IR情報・業界紙などの一般的に入手可能な情報の範囲内で、質問の意図に一定資すると思われる情報を話そうと考えている。
    
    Aの考えは適切か否か。
    `,
    correctAnswer: "適切である",
    explanation: `
    Aは、秘密情報とそれ以外の情報とをしっかり区分できており、後者の範囲で回答しようとしており、適切な考え方といえます。
    `,
  },
  {
    title: "次の内容において、Aの考えは適切であると言えるか",
    question: `
    エキスパート登録者であるAは、電子部品販売店であるX社の営業部長として勤務している。
    インタビューの中でクライアントより、X社と取引がある製造元に関する財務情報・事業戦略の中身について質問を受けた。Aは取引を通じて製造元に関する財務情報・事業戦略の詳細を把握しており、把握している情報はすべて自らの知見として話すことができると考え、すべての質問に回答した。
    
    Aの考えは適切か否か。
    `,
    correctAnswer: "適切ではない",
    explanation: `
    特定の企業の財務情報・事業戦略の中身は、公開されているものでなければ、秘密情報にあたる可能性が高い情報といえます。代理店・販売店の従業員は、再販、供給または販売を行っている製造元企業に関する財務情報・事業戦略を、取引等を通じて知っている場合がありえますが、そのような情報は同企業との関係で秘密保持義務の対象となっている可能性が高く、そうでなくても一般に慎重に取り扱うべき情報といえます。そのように考えることなく、自らの知見としてお話しできると考えてはなりません。
    `,
  },
];
