import { injectable } from "tsyringe";
import { Optional } from "typescript-optional";

import {
  MailAddress,
  Me,
  Name,
  Career,
  Careers,
  CompanyName,
  PhoneNumber,
  CareerSummary,
  Department,
  Position,
  CareerDetail,
  EnrollmentPeriod,
  Enrolled,
  Year,
  YearMonth,
  Month,
  SpecializedDomainId,
  SpecializedDomainIds,
  Birthday,
  IndustryIds,
  IndustryId,
  ExpertId,
  Permissions,
  ResidentRegionId,
  Reference,
  CareerReference,
  BriefHistoryReference,
  CorporateNumber,
} from "@/lib/domain";
import { Address } from "@/lib/domain/Address";
import { PostalCode } from "@/lib/domain/PostalCode";
import { NewspicksExpertApi, NewspicksExpertAuthApi } from "@/lib/driver";
import { MePort } from "@/lib/port";

@injectable()
export class MeGateway implements MePort {
  constructor(
    readonly api: NewspicksExpertApi,
    readonly authApi: NewspicksExpertAuthApi,
  ) {}

  async findMe(): Promise<Me> {
    const {
      id,
      mailAddress,
      name,
      birthday,
      careers,
      phoneNumber,
      residentRegionId,
      address,
      postalCode,
      specializedDomains,
      industries,
      careerSummary,
      permissions,
      careerReference,
      briefHistoryReference,
    } = await this.api.findMe();
    return new Me(
      new ExpertId(id),
      new MailAddress(mailAddress),
      Optional.ofNullable(name)
        .map((name) => new Name(name))
        .orElse(Name.empty()),
      Optional.ofNullable(birthday)
        .map((v) => new Birthday(new Date(v)))
        .orNull(),
      Optional.ofNullable(phoneNumber)
        .map((v) => new PhoneNumber(v))
        .orElse(PhoneNumber.empty()),
      Optional.ofNullable(residentRegionId)
        .map((v) => new ResidentRegionId(v))
        .orElse(ResidentRegionId.empty()),
      Optional.ofNullable(address)
        .map((v) => new Address(v))
        .orElse(Address.empty()),
      Optional.ofNullable(postalCode)
        .map((v) => new PostalCode(v))
        .orElse(PostalCode.empty()),
      new SpecializedDomainIds(specializedDomains.map((it) => new SpecializedDomainId(it.id))),
      new IndustryIds(industries.map((it) => new IndustryId(it.id))),
      Optional.ofNullable(careerSummary)
        .map((v) => new CareerSummary(v))
        .orElse(CareerSummary.empty()),
      new Careers(
        careers.map(
          (it) =>
            new Career(
              Optional.ofNullable(it.companyName)
                .map((companyName) => new CompanyName(companyName))
                .orElse(CompanyName.empty()),
              Optional.ofNullable(it.corporateNumber)
                .map((v) => new CorporateNumber(v))
                .orElse(CorporateNumber.empty()),
              Optional.ofNullable(it.department)
                .map((v) => new Department(v))
                .orElse(Department.empty()),
              Optional.ofNullable(it.position)
                .map((v) => new Position(v))
                .orElse(Position.empty()),
              Optional.ofNullable(it.careerDetail)
                .map((v) => new CareerDetail(v))
                .orElse(CareerDetail.empty()),
              new EnrollmentPeriod(
                new YearMonth(
                  Optional.ofNullable(it.enrollmentPeriod.from.year)
                    .map((v) => new Year(v))
                    .orNull(),
                  Optional.ofNullable(it.enrollmentPeriod.from.month)
                    .map((v) => new Month(v))
                    .orNull(),
                ),
                new YearMonth(
                  Optional.ofNullable(it.enrollmentPeriod.to.year)
                    .map((v) => new Year(v))
                    .orNull(),
                  Optional.ofNullable(it.enrollmentPeriod.to.month)
                    .map((v) => new Month(v))
                    .orNull(),
                ),
                Optional.ofNullable(it.enrollmentPeriod.enrolled)
                  .map((v) => new Enrolled(v))
                  .orElse(Enrolled.empty()),
              ),
            ),
        ),
      ),
      permissions as Permissions,
      new Reference(
        Optional.ofNullable(careerReference)
          .map((v) => new CareerReference(v))
          .orNull(),
        Optional.ofNullable(briefHistoryReference)
          .map((v) => new BriefHistoryReference(v))
          .orNull(),
      ),
    );
  }

  async store(me: Me): Promise<void> {
    await this.api.storeMe({
      name: me.name.value,
      birthday: me.birthday && me.birthday.toISODateString(),
      mailAddress: me.mailAddress.value,
      phoneNumber: me.phoneNumber.value,
      residentRegionId: me.residentRegionId && me.residentRegionId.value,
      address: me.address && me.address.value,
      postalCode: me.postalCode && me.postalCode.value,
      industries: me.industies
        .filter((it) => it.value)
        .map((it) => ({
          id: it.value,
        })),
      specializedDomains: me.specializedDomainIds
        .filter((it) => it.value)
        .map((it) => ({
          id: it.value,
        })),
      careerSummary: me.careerSummary.value,
      careers: me.careers.map((it) => ({
        companyName: it.companyName.value,
        department: it.department.value,
        position: it.position.value,
        careerDetail: it.detail.value,
        enrollmentPeriod: {
          from: {
            year: it.enrollmentPeriod.from.year?.value ?? null,
            month: it.enrollmentPeriod.from.month?.value ?? null,
          },
          to: !it.enrollmentPeriod.enrolled.value
            ? {
                year: it.enrollmentPeriod.to.year?.value ?? null,
                month: it.enrollmentPeriod.to.month?.value ?? null,
              }
            : undefined,
          enrolled: it.enrollmentPeriod.enrolled.value,
        },
      })),
    });
  }
}
