export const DEV_AGREEMENT_QUESTIONS = [
  {
    title: "タイトル",
    text: `これは **マークダウン**です!`,
  },
  {
    title: "ご登録に際しての注意事項",
    text: `
    - 登録に際し、氏名・生年月日・連絡先・経歴等の正確な情報をご提供頂く必要があります。
    <div style={{ whiteSpace: "normal" }}>
    <MdCautionaryNote>※氏名は実名のご提供をお願いします（ビジネスネームでは登録できません）。</MdCautionaryNote>
    <MdCautionaryNote>※登録後、提供情報に変更があった場合には、すみやかに変更申請を行い、最新情報を維持するようにしてください。</MdCautionaryNote>
    </div>

    - 犯罪歴を有している方、業法違反、資格はく奪、業界団体における懲罰その他これに類似するペナルティを受けたことのある方はご登録できません。
    - ご所属企業における副業のルール（就業規則等）をご確認ください。副業禁止の場合には登録できませんし、許可等の手続が必要な場合には手続を履践してからご登録ください。
    - ミーミルが定めるエキスパート規約は、エキスパートの皆様が活動するに際してのルールを定めたものです。内容を読み理解した上で活動を行ってください。
    [https://newspicks.expert/rules](https://newspicks.expert/rules)`,
  },
];
