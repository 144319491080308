export class MailAddress {
  constructor(readonly value: string) {}
  isValid(): boolean {
    if (
      !this.value.match(/^[^\s]+@[^\s]+\.[^\s]{2,}$/) ||
      !this.value.match(/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/) ||
      this.value.length > 100
    ) {
      return false;
    }
    return true;
  }
}
