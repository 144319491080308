import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

export const initialize = () => {
  if (!process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) {
    throw new Error("NEXT_PUBLIC_BUGSNAG_API_KEY is not set.");
  }

  return Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    appVersion: process.env.NEXT_PUBLIC_APP_VERSION,
    releaseStage: process.env.NEXT_PUBLIC_APP_ENV,
    enabledReleaseStages: ["production", "development"],
  });
};

const bugsnag = initialize();

export { bugsnag };
