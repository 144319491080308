import { FCC } from "./FCC";

export class IndustryId {
  constructor(readonly value: string) {}
}

export class IndustryIds extends FCC<IndustryId> {
  static empty() {
    return new IndustryIds([]);
  }
}

export class IndustryName {
  constructor(readonly value: string) {}
}

export class Industries extends FCC<Industry> {
  static empty() {
    return new Industries([]);
  }
}

export class Industry {
  constructor(
    readonly id: IndustryId,
    readonly name: IndustryName,
  ) {}
}
