export const BANK_ACCOUNT = "BANK_ACCOUNT" as const;
export const NOT_RECEIVED = "NOT_RECEIVED" as const;

export const RECEPTION_TYPE = {
  bankAccount: BANK_ACCOUNT,
  notReceived: NOT_RECEIVED,
};

export const PERSONAL_ACCOUNT = "PERSONAL_ACCOUNT" as const;
export const CORPORATION_ACCOUNT = "CORPORATION_ACCOUNT" as const;

export const OWNER_TYPE = {
  personal: PERSONAL_ACCOUNT,
  corporation: CORPORATION_ACCOUNT,
} as const;

export const SAVINGS = "SAVINGS" as const;
export const CURRENT = "CURRENT" as const;

export const ACCOUNT_TYPE = {
  savings: SAVINGS,
  current: CURRENT,
};
