import Head from "next/head";

type Props = {
  title: string;
  noindex?: boolean;
  children: React.ReactNode;
};

export const PageRoot: React.FC<Props> = (props) => {
  const pageTitle = props.title === "" ? "NewsPicks Expert" : `${props.title} | NewsPicks Expert`;
  return (
    <>
      <Head>
        <title data-testid="page-title">{pageTitle}</title>
        {props.noindex && <meta name="robots" content="noindex" />}
      </Head>
      {props.children}
    </>
  );
};
