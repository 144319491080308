import { css } from "@emotion/react";

import { ExternalLink } from "@/components/atoms/ExternalLink";
import { mq } from "@/lib/styleTokens";

export const Footer: React.FC = () => {
  return (
    <footer data-testid="footer" css={footer}>
      <div css={footerInner}>
        <ExternalLink
          href={"https://newspicks.expert/contact"}
          target={"_blank"}
          style={linkStyle}
          testid={"footer-contact-link"}>
          <span data-testid="footer-contact">お問い合わせ</span>
        </ExternalLink>
        <ExternalLink
          href={"https://newspicks.expert/support/forms/referral"}
          target={"_blank"}
          style={linkStyle}
          testid={"footer-invite-link"}>
          <span data-testid="footer-invite">知人のご紹介</span>
        </ExternalLink>
        <ExternalLink
          href={"https://newspicks.expert/ext-modules"}
          target={"_blank"}
          style={linkStyle}
          testid={"footer-external-link"}>
          <span data-testid="footer-external">利用者に関する情報の外部送信について</span>
        </ExternalLink>
        <small css={copyright} data-testid="footer-copyright">
          © Mimir, Inc.
        </small>
      </div>
    </footer>
  );
};

const footer = css`
  background-color: var(--color-footer-background);

  ${mq.mobile} {
    margin-top: 80px;
  }
`;
const footerInner = css`
  max-width: 1232px;
  margin: 0 auto;
  display: flex;
  height: 100px;
  align-items: center;
  gap: 24px;

  ${mq.tablet} {
    max-width: auto;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 32px 16px;
    height: auto;
  }

  ${mq.mobile} {
    width: 100%;
    max-width: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 32px 16px;
    height: auto;
  }
`;

const linkStyle = css`
  font-size: 14px;
  line-height: 100%;
  color: #222;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: #222 !important;
  }
`;
const copyright = css`
  margin-left: auto;
  font-size: 12px;

  ${mq.tablet} {
    margin-left: 0;
    margin-top: 32px;
  }

  ${mq.mobile} {
    margin-left: 0;
    margin-top: 32px;
  }
`;
