import { useMemo } from "react";

import { css } from "@emotion/react";

import { PageHeading } from "@/components/molecules/PageHeading";
import { MeJsonV2 } from "@/lib/driver/NewspicksExpertApi";
import { colors, mq } from "@/lib/styleTokens";
import { Footer } from "@/ui/layout/Footer";
import { Header } from "@/ui/layout/Header";

type Props = {
  expert?: MeJsonV2;
  title?: string;
  explanation?: React.ReactNode;
  children: React.ReactNode;
  testid?: string;
  width?: "wide" | "standard";
  isPrintOnlyChildren?: boolean;
};

export const Layout = ({
  expert,
  title,
  explanation,
  testid,
  children,
  width = "standard",
  isPrintOnlyChildren = false,
}: Props) => {
  const printDisplayStyle = useMemo(() => {
    return `@media print {
          display: ${isPrintOnlyChildren ? "none" : "block"};
        }`;
  }, [isPrintOnlyChildren]);

  return (
    <div
      data-testid={testid}
      css={css`
        width: 100%;
        padding: 0;
        min-height: 100vh;
        @media print {
          ${isPrintOnlyChildren ? `background: ${colors.white};  @page {size: A4 landscape;}` : ""}
        }
      `}>
      <div
        css={css`
          width: 100%;
          display: flex;
          justify-content: center;
          position: sticky;
          z-index: 2;
          top: 0;
          ${printDisplayStyle}
        `}>
        <Header name={expert?.name} supportId={expert?.id} mimirExpertNumber={expert?.mimirExpertNumber} />
      </div>
      <main
        css={css`
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          min-height: calc(100vh - 160px);
          z-index: 1;
          ${mq.mobile} {
            padding: 0 16px 0;
            width: 100%;
            min-height: calc(100vh - 160px);
          }
          ${mq.tablet} {
            padding: 0 16px 0;
            width: 100%;
          }
        `}>
        <div
          css={css`
            max-width: ${width === "wide" ? "1232px" : "808px"};
            width: 100%;
            padding-top: 40px;
            padding-bottom: 80px;
            ${mq.mobile} {
              width: 100%;
              padding-bottom: 24px;
            }
            ${mq.tablet} {
              width: 100%;
            }
          `}>
          {title && (
            <section
              css={css`
                margin-bottom: 40px;
                ${printDisplayStyle}
              `}>
              <PageHeading title={title} explanation={explanation} />
            </section>
          )}
          {children}
        </div>
      </main>
      <div
        css={css`
          z-index: 2;
          ${printDisplayStyle}
        `}>
        <Footer />
      </div>
    </div>
  );
};
