import { FCC } from "./FCC";

export class ResidentRegions extends FCC<ResidentRegion> {
  static empty() {
    return new ResidentRegions([]);
  }
}

export class ResidentRegionId {
  constructor(readonly value: string) {}
  static empty() {
    return new ResidentRegionId("");
  }
  static from(residentRegionId: string): ResidentRegionId | null {
    return residentRegionId ? new ResidentRegionId(residentRegionId) : null;
  }
}
export class ResidentRegionName {
  constructor(readonly value: string) {}
}

export class ResidentRegion {
  constructor(
    readonly id: ResidentRegionId,
    readonly name: ResidentRegionName,
  ) {}
  static empty() {
    return new ResidentRegion(new ResidentRegionId(""), new ResidentRegionName(""));
  }
}
