import { injectable } from "tsyringe";

import { SpecializedDomain, SpecializedDomains, SpecializedDomainId, SpecializedDomainName } from "@/lib/domain";
import { SpecializedDomains as SpecializedDomainsDriver } from "@/lib/driver";
import { SpecializedDomainsPort } from "@/lib/port";

@injectable()
export class SpecializedDomainsGateway implements SpecializedDomainsPort {
  constructor(readonly driver: SpecializedDomainsDriver) {}

  find(): SpecializedDomains {
    return new SpecializedDomains(this.driver.getAll().map(this.toSpecializedDomain));
  }

  toSpecializedDomain({ id, name }: { id: string; name: string }): SpecializedDomain {
    return new SpecializedDomain(new SpecializedDomainId(id), new SpecializedDomainName(name));
  }
}
