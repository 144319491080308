import { FCC } from "./FCC";

export class SpecializedDomainId {
  constructor(readonly value: string) {}
}

export class SpecializedDomainIds extends FCC<SpecializedDomainId> {
  static empty() {
    return new SpecializedDomainIds([]);
  }
}

export class SpecializedDomainName {
  constructor(readonly value: string) {}
}

export class SpecializedDomains extends FCC<SpecializedDomain> {
  static empty() {
    return new SpecializedDomains([]);
  }
}

export class SpecializedDomain {
  constructor(
    readonly id: SpecializedDomainId,
    readonly name: SpecializedDomainName,
  ) {}
}
