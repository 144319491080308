import {
  MailAddress,
  Name,
  PhoneNumber,
  CareerSummary,
  Careers,
  SpecializedDomainIds,
  Birthday,
  IndustryIds,
  ExpertId,
  Permissions,
  ResidentRegionId,
  Address,
  PostalCode,
  Reference,
} from ".";

export class Me {
  constructor(
    readonly expertId: ExpertId,
    readonly mailAddress: MailAddress,
    readonly name: Name,
    readonly birthday: Birthday | null,
    readonly phoneNumber: PhoneNumber,
    readonly residentRegionId: ResidentRegionId | null,
    readonly address: Address | null,
    readonly postalCode: PostalCode | null,
    readonly specializedDomainIds: SpecializedDomainIds,
    readonly industies: IndustryIds,
    readonly careerSummary: CareerSummary,
    readonly careers: Careers,
    readonly permissions: Permissions,
    readonly reference?: Reference,
  ) {}
}
