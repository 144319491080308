import { MailAddress } from "./MailAddress";
import { PhoneNumber } from "./PhoneNumber";

export class AccountRegistration {
  constructor(
    readonly mailAddress: MailAddress,
    readonly registered: boolean,
    readonly phoneNumber?: PhoneNumber,
  ) {}
}
