import { PropsWithChildren } from "react";

import { SerializedStyles, css } from "@emotion/react";

type FlexProps = {
  flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";
  justifyContent?: "center" | "start" | "end" | "space-between" | "space-around";
  alignItems?: "center" | "start" | "end";
  gap?: number;
  style?: SerializedStyles;
  dataTestId?: string;
};

export const Flex: React.FC<PropsWithChildren<FlexProps>> = ({
  flexDirection,
  justifyContent,
  alignItems,
  gap = 0,
  children,
  style,
  dataTestId,
}) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: ${flexDirection};
        justify-content: ${justifyContent};
        align-items: ${alignItems};
        gap: ${gap}px;
        ${style}
      `}
      data-testid={dataTestId}>
      {children}
    </div>
  );
};
