import { injectable } from "tsyringe";

import { ResidentRegion, ResidentRegionId, ResidentRegionName, ResidentRegions } from "@/lib/domain";
import { ResidentRegions as ResidentRegionsDriver } from "@/lib/driver";
import { ResidentRegionsPort } from "@/lib/port/ResidentRegionsPort";

@injectable()
export class ResidentRegionsGateway implements ResidentRegionsPort {
  constructor(readonly driver: ResidentRegionsDriver) {}

  find(): ResidentRegions {
    return new ResidentRegions(this.driver.getAll().map(this.toResidentRegion));
  }

  toResidentRegion({ id, name }: { id: string; name: string }): ResidentRegion {
    return new ResidentRegion(new ResidentRegionId(id), new ResidentRegionName(name));
  }
}
