import { injectable } from "tsyringe";

import { AccountRegistration, ConversionPath, MailAddress, Token, Referral, PhoneNumber } from "@/lib/domain";
import { NewspicksExpertApi } from "@/lib/driver";
import { TokenExpired, AccountRegistrationError } from "@/lib/Errors";
import { AccountRegistrationPort } from "@/lib/port";

@injectable()
export class AccountRegistrationGateway implements AccountRegistrationPort {
  constructor(readonly driver: NewspicksExpertApi) {}

  async store(
    mailAddress: MailAddress,
    conversionPath: ConversionPath | null,
    referral: Referral | null,
  ): Promise<void | AccountRegistrationError> {
    const result = await this.driver.postAccountRegistration({
      mailAddress: mailAddress.value,
      conversionPathId: conversionPath?.value,
      referral: referral
        ? {
            tokey: referral?.tokey ?? null,
            suid: referral?.suid ?? null,
            code: referral?.code ?? null,
          }
        : undefined,
    });
    if (result.status === 400) {
      throw new AccountRegistrationError();
    }
  }

  async find(token: Token): Promise<AccountRegistration> {
    try {
      const result = await this.driver.getAccountRegistration(token.value);
      if (result.status === 200) {
        return new AccountRegistration(
          new MailAddress(result.data.mailAddress),
          result.data.isRegistered,
          result.data.phoneNumber ? new PhoneNumber(result.data.phoneNumber) : undefined,
        );
      }
      throw new TokenExpired();
    } catch (e) {
      throw new TokenExpired();
    }
  }
}
