import { injectable } from "tsyringe";

import { CaptchaToken } from "@/lib/domain";
import { ReCaptchaTokenGenerator } from "@/lib/driver";
import { CaptchaTokenPort } from "@/lib/port";

@injectable()
export class CaptchaTokenGateway implements CaptchaTokenPort {
  constructor(readonly driver: ReCaptchaTokenGenerator) {}
  async create(): Promise<CaptchaToken> {
    const token = await this.driver.createToken();
    return new CaptchaToken(token);
  }
}
