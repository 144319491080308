import axios, { AxiosResponse } from "axios";
import moji from "moji";
import QueryString from "qs";
import { injectable } from "tsyringe";

import { OWNER_TYPE, RECEPTION_TYPE } from "@/lib/const/bank/bank";
import { WAFError } from "@/lib/Errors";
import { RewardInputType } from "@/lib/validation/bank";

import { client } from "./HttpClient";
import { ComplianceTrainingStatusJson } from "./json/complianceTraining";
import { ProfileRequiredFilledJson } from "./json/profileRequiredFilled";
import {
  RewardsMonthlySummaries,
  RewardsJson,
  RewardsPaymentMethod,
  RewardsProjectType,
  RewardsFacets,
} from "./json/rewards";

export type SpecializedDomainJson = {
  id: string;
};

export type IndustryJson = {
  id: string;
};

export type CareerJson = {
  companyName: string | null;
  corporateNumber: string | null;
  department: string | null;
  position: string | null;
  careerDetail: string | null;
  enrollmentPeriod: {
    from: {
      year: number | null;
      month: number | null;
    };
    to: {
      year: number | null;
      month: number | null;
    };
    enrolled: boolean | null;
  };
};

export type LectureJson = {
  id: string;
  eventName: string;
  title: string | null;
  year: number | null;
  month: number | null;
  eventUrl: string | null;
};

export type PublishedBookJson = {
  id: string;
  title: string;
  year: number | null;
  month: number | null;
};

export type MeJson = {
  id: number;
  mailAddress: string;
  name: string | null;
  birthday: string | null;
  phoneNumber: string | null;
  residentRegionId: string | null;
  address: string | null;
  postalCode: string | null;
  specializedDomains: Array<SpecializedDomainJson>;
  industries: Array<IndustryJson>;
  lectures: LectureJson[];
  publishedBooks: PublishedBookJson[];
  careerSummary: string | null;
  careers: Array<CareerJson>;
  permissions: string[];
  careerReference: string | null;
  briefHistoryReference: string | null;
};

export type MeJsonV2 = {
  id: number;
  mimirExpertNumber: number | null;
  name: string;
  permissions: string[];
  screeningStatus: "PROCESSING" | "DONE";
};

export type PutCareerJson = {
  companyName: string;
  department: string;
  position: string;
  careerDetail: string;
  enrollmentPeriod: {
    from: {
      year: number | null;
      month: number | null;
    };
    to?: {
      year: number | null;
      month: number | null;
    };
    enrolled: boolean;
  };
};

export type PutLectureJson = {
  id?: string;
  eventName: string;
  title: string | null;
  year: number | null;
  month: number | null;
  eventUrl: string | null;
};

export type PutPublishedBookJson = {
  id?: string;
  title: string;
  year: number | null;
  month: number | null;
};

export type PutMeJson = {
  name: string;
  birthday: string | null;
  mailAddress: string;
  phoneNumber: string;
  address: string | null;
  residentRegionId: string | null;
  postalCode: string | null;
  industries: Array<{
    id: string;
  }>;
  specializedDomains: Array<{
    id: string;
  }>;
  lectures: Array<PutLectureJson>;
  publishedBooks: Array<PutPublishedBookJson>;
  careerSummary: string;
  careers: Array<PutCareerJson>;
};

export type PutMePartialJson = Partial<PutMeJson>;

export type ReferralJson = {
  tokey: string | null;
  suid: string | null;
  code: string | null;
};

export type PostAccountRegistrationJson = {
  mailAddress: string;
  conversionPathId?: string;
  referral?: ReferralJson;
};

export type RewardsParams = {
  year: number;
  limit: number;
  offset: number;
  projectType: RewardsProjectType[];
  paymentMethod: RewardsPaymentMethod[];
};

export type RewardInfoJson = {
  receptionType: string;
  invoice: {
    isIssuer: boolean;
    number: string | null;
  } | null;
  bankAccount: {
    ownerName: string;
    accountNumber: string;
    accountType: string;
    bank: {
      name: string;
      branchName: string;
    };
  } | null;
};

export type PostRewardSettingsJson = {
  receptionType: string;
  bankAccount?: {
    ownerType: string;
    bankCode: string;
    branchCode: string;
    accountType: string;
    ownerName: string;
    accountNumber: string;
    corporation?: {
      name: string;
      number: string;
      address: string;
    };
  };
  invoice?: {
    number: string;
  };
};

export type PaymentResultJson = {
  paymentResult: {
    payment: number;
    withholdingTax: number;
    fiscalYear: number;
    name: string;
    address: string | null;
  };
};

export const toRegisterRewardInfo = (rewardInput: RewardInputType): PostRewardSettingsJson => {
  if (rewardInput.receptionType === RECEPTION_TYPE.notReceived) {
    return {
      receptionType: RECEPTION_TYPE.notReceived,
    };
  }
  switch (rewardInput.ownerType) {
    case OWNER_TYPE.personal:
      return {
        receptionType: RECEPTION_TYPE.bankAccount,
        bankAccount: {
          ownerType: rewardInput.ownerType,
          bankCode: rewardInput.bank.code,
          branchCode: rewardInput.branch.code,
          accountType: rewardInput.accountType,
          ownerName: normalizeOwnerName(rewardInput.ownerName),
          accountNumber: rewardInput.accountNumber,
        },
        invoice: {
          number: rewardInput.invoiceNumber,
        },
      };

    case OWNER_TYPE.corporation:
      return {
        receptionType: RECEPTION_TYPE.bankAccount,
        bankAccount: {
          ownerType: rewardInput.ownerType,
          bankCode: rewardInput.bank.code,
          branchCode: rewardInput.branch.code,
          accountType: rewardInput.accountType,
          ownerName: normalizeOwnerName(rewardInput.ownerName),
          accountNumber: rewardInput.accountNumber,
          corporation: {
            name: rewardInput.corporationName,
            number: rewardInput.corporationNumber,
            address: rewardInput.corporationAddress,
          },
        },
        invoice: {
          number: rewardInput.invoiceNumber,
        },
      };
    default:
      throw new Error("todo");
  }
};

export type PostSearchCorporationsJson = {
  query: {
    name: string;
    prefectureCode?: string;
  };
  page: number;
  perPage: number;
};

export type Corporation = {
  name: string;
  corporateNumber: string | null;
  location: string;
  closed: boolean;
};

export type SearchCorporationsResultJson = {
  page: number;
  perPage: number;
  hasNextPage: boolean;
  corporations: Corporation[];
};

export type SearchBanksJson = {
  data: { name: string; code: string }[];
};

export type SearchBranchesJson = {
  data: { name: string; code: string }[];
};

@injectable()
export class NewspicksExpertApi {
  private abortCtr: AbortController;

  constructor() {
    this.abortCtr = new AbortController();
  }
  async findMe(): Promise<MeJson> {
    const response = await client.get<MeJson>("/api/v1/me");
    return response.data;
  }

  async findMeV2FromServer(token: string): Promise<MeJsonV2> {
    const response = await client.get<MeJsonV2>("/api/v2/me", { headers: { Cookie: `npex-token=${token}` } });
    return response.data;
  }

  async findMeV2(): Promise<MeJsonV2> {
    const response = await client.get<MeJsonV2>("/api/v2/me");
    return response.data;
  }

  async storeMe(putMe: PutMePartialJson): Promise<void> {
    await client.put("/api/v2/me", putMe);
  }

  async postAccountRegistration(
    postAccountRegistrationJson: PostAccountRegistrationJson,
  ): Promise<AxiosResponse<void>> {
    return await client.post("/api/v1/accountRegistrations", postAccountRegistrationJson);
  }

  async getAccountRegistration(token: string): Promise<
    AxiosResponse<{
      token: string;
      mailAddress: string;
      isRegistered: boolean;
      phoneNumber?: string;
    }>
  > {
    return await client.get(`/api/v1/accountRegistrations/${token}`);
  }

  async storeContactEmailModification(email: string): Promise<AxiosResponse<void>> {
    return await client.post("/api/v1/contactEmailModifications", { mailAddress: email });
  }

  async findContactEmailModification(token: string): Promise<AxiosResponse<void>> {
    return await client.get(`/api/v1/contactEmailModifications/${token}`);
  }

  async updateContactEmail(contactEmailToken: string): Promise<AxiosResponse<void>> {
    return await client.put(`/api/v1/contactEmail`, { token: contactEmailToken });
  }

  async sendUnauthorizedPhoneNumber(phoneNumber: string, token: string): Promise<AxiosResponse<void>> {
    return await client.post(`/api/v1/unauthorizedPhoneNumber`, { phoneNumber, token });
  }

  async verifyPhoneNumber(verifyCode: string, token: string): Promise<AxiosResponse<void>> {
    return await client.post(`/api/v1/phoneNumberVerification`, { verifyCode, token });
  }

  async storePhoneNumberModification(phoneNumber: string): Promise<AxiosResponse<void>> {
    return await client.post("/api/v1/phoneNumberModifications", { phoneNumber });
  }

  async updatePhoneNumber(verifyCode: string): Promise<UpdatePhoneNumberResponse> {
    try {
      await client.put("/api/v1/me/phoneNumber", { verifyCode });
      return { result: "Created" };
    } catch (e) {
      if (axios.isAxiosError(e)) {
        switch (e.response?.status) {
          case 400:
            return { result: "BadRequest" };
          case 409:
            return { result: "Conflict" };
          default:
            return { result: "InternalServerError" };
        }
      } else if (e instanceof WAFError) {
        return { result: "Forbidden" };
      } else {
        return { result: "InternalServerError" };
      }
    }
  }

  async deleteReference(): Promise<AxiosResponse<void>> {
    return await client.delete("/api/v1/me/reference");
  }

  async registerComplianceTrainingResult(isPassed: boolean): Promise<void> {
    return await client.post("/api/v1/me/complianceTrainingResults", {
      isPassed,
    });
  }

  getRewards(params: RewardsParams): Promise<AxiosResponse<RewardsJson>> {
    return client.get("/api/v1/me/rewards", {
      params,
      paramsSerializer: (params) => {
        return QueryString.stringify(params, { arrayFormat: "comma" });
      },
    });
  }

  getRewardsMonthlySummaries(year: number): Promise<AxiosResponse<RewardsMonthlySummaries>> {
    return client.get(`/api/v1/me/rewards/monthlySummaries`, {
      params: {
        year,
      },
    });
  }

  getRewardsFacets(year: number): Promise<AxiosResponse<RewardsFacets>> {
    return client.get("/api/v1/me/rewards/facets", {
      params: {
        year,
      },
    });
  }

  async getRewardInfo(): Promise<AxiosResponse<RewardInfoJson>> {
    return await client.get("/api/v1/me/rewardSettings");
  }

  async registerRewardInfo(postRewardSettings: PostRewardSettingsJson): Promise<AxiosResponse<void>> {
    return await client.post("/api/v1/me/rewardSettings", postRewardSettings);
  }

  async searchCorporations(postSearchCorporations: PostSearchCorporationsJson): Promise<SearchCorporationsResultJson> {
    const response = await client.post("/api/v1/corporations/search", postSearchCorporations);
    return response.data;
  }

  async searchBanks(keyword: string): Promise<AxiosResponse<SearchBanksJson>> {
    if (this.abortCtr) {
      this.abortCtr.abort();
    }
    this.abortCtr = new AbortController();

    return await client.get("/api/v1/banks", {
      params: { keyword },
      signal: this.abortCtr.signal,
    });
  }

  async searchBranches(bankCode: string, keyword: string): Promise<AxiosResponse<SearchBranchesJson>> {
    if (this.abortCtr) {
      this.abortCtr.abort();
    }
    this.abortCtr = new AbortController();
    return await client.get(`/api/v1/banks/${bankCode}/branches`, {
      params: { keyword },
      signal: this.abortCtr.signal,
    });
  }

  async getPaymentResult(): Promise<AxiosResponse<PaymentResultJson>> {
    return await client.get("/api/v1/me/paymentResult");
  }

  async getComplianceTrainingStatus(): Promise<AxiosResponse<ComplianceTrainingStatusJson>> {
    return await client.get("/api/v1/me/complianceTrainingStatus");
  }

  async getProfileRequiredFilled(): Promise<AxiosResponse<ProfileRequiredFilledJson>> {
    return await client.get("/api/v1/me/profile/requiredFilled");
  }
}

type UpdatePhoneNumberResponse = {
  result: "Created" | "BadRequest" | "Forbidden" | "Conflict" | "InternalServerError";
};

const specialCharacterMappings = [
  { fullWidth: "，", halfWidth: "," },
  { fullWidth: "．", halfWidth: "." },
  { fullWidth: "―", halfWidth: "-" },
  { fullWidth: "／", halfWidth: "/" },
  { fullWidth: "「", halfWidth: "｢" },
  { fullWidth: "」", halfWidth: "｣" },
  { fullWidth: "（", halfWidth: "(" },
  { fullWidth: "）", halfWidth: ")" },
  { fullWidth: "￥", halfWidth: "¥" },
];

const youonSokuonToSeionMapping: { [key: string]: string } = {
  ァ: "ア",
  ィ: "イ",
  ゥ: "ウ",
  ェ: "エ",
  ォ: "オ",
  ャ: "ヤ",
  ュ: "ユ",
  ョ: "ヨ",
  ヮ: "ワ",
  ッ: "ツ",
  ｧ: "ア",
  ｨ: "イ",
  ｩ: "ウ",
  ｪ: "エ",
  ｫ: "オ",
  ｬ: "ヤ",
  ｭ: "ユ",
  ｮ: "ヨ",
  ｯ: "ツ",
};

export const toHalfWidth = (text: string) =>
  moji(text)
    .convert("ZK", "HK")
    .convert("ZE", "HE")
    .convert("ZS", "HS")
    .toString()
    .split("")
    .map((char) => specialCharacterMappings.find((mapping) => mapping.fullWidth === char)?.halfWidth || char)
    .join("");

export const normalizeOwnerName = (text: string) => {
  return toHalfWidth(
    text
      .split("")
      .map((c) => (youonSokuonToSeionMapping[c] ? youonSokuonToSeionMapping[c] : c))
      .join(""),
  ).toUpperCase();
};

export const expertApi = new NewspicksExpertApi();
