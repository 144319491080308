import axios, { AxiosResponse } from "axios";
import { mutate } from "swr";
import { injectable } from "tsyringe";

import { MailAddressAlreadyUsed, NoMyPageError, PasswordMismatch, UnauthorizedError, WAFError } from "@/lib/Errors";

import { client } from "./HttpClient";
import { ReCaptchaTokenGenerator } from "./ReCaptchaTokenGenerator";

export type JwtJson = {
  token: string;
};

@injectable()
export class NewspicksExpertAuthApi {
  constructor(readonly reCaptchaTokenGenerator: ReCaptchaTokenGenerator) {}
  async signIn(mailAddress: string, password: string): Promise<JwtJson> {
    const postJson = { mailAddress, password };

    try {
      const response = await client.post<JwtJson>("/auth/v1/login", postJson);
      return response.data;
    } catch (e) {
      if (e instanceof WAFError) {
        throw e;
      }
      if (axios.isAxiosError(e)) {
        switch (e.response?.status) {
          case 401:
            throw new UnauthorizedError("Failed to sign In");
          case 404:
            throw new NoMyPageError("No my page");
          default:
            throw e;
        }
      } else {
        throw e;
      }
    }
  }

  async registerV3(mailAddress: string, password: string, token: string): Promise<AxiosResponse<void>> {
    try {
      return await client.post("/auth/v3/register", { mailAddress, password, token });
    } catch (e) {
      if (e instanceof WAFError) {
        throw e;
      }
      if (axios.isAxiosError(e)) {
        switch (e.response?.status) {
          case 401:
            throw new PasswordMismatch();
          case 409:
            throw new MailAddressAlreadyUsed();
          default:
            throw e;
        }
      } else {
        throw e;
      }
    }
  }

  async signOut(): Promise<void> {
    await client.delete("/auth/v1/session");
    mutate(() => true, undefined, { revalidate: false });
  }

  async authorizeWithToken(token: string): Promise<string> {
    const res = await client.get<{ npUserId: string }>("/auth/v1/authz", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { npUserId } = res.data;
    return npUserId;
  }

  async captcha(captchaToken: string): Promise<void> {
    return await client.post("/auth/v1/captcha", { captchaToken });
  }
}

export const authApi = new NewspicksExpertAuthApi(new ReCaptchaTokenGenerator());
