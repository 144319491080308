import "reflect-metadata";

import React, { FC, PropsWithChildren } from "react";

import { container } from "tsyringe";

import {
  MeGateway,
  SpecializedDomainsGateway,
  IndustriesGateway,
  ResidentRegionsGateway,
  AccountRegistrationGateway,
  CaptchaGateway,
  CaptchaTokenGateway,
  ReferralGateway,
  ContactEmailModificationGateway,
} from "@/lib/gateway";
import { ContactEmailGateway } from "@/lib/gateway/ContactEmailGateway";
import { ConversionPathGateway } from "@/lib/gateway/ConversionPathGateway";
import { bugsnag } from "@/lib/utils/bugsnag";
import ErrorView from "@/pages/_ErrorView.page";

import { LeaveConfirmProvider } from "./leaveConfirm/LeaveConfirmContext";
import { ToastProvider } from "./toast/ToastContext";

container.register("MePort", { useClass: MeGateway });
container.register("SpecializedDomainsPort", { useClass: SpecializedDomainsGateway });
container.register("IndustriesPort", { useClass: IndustriesGateway });
container.register("ResidentRegionsPort", { useClass: ResidentRegionsGateway });
container.register("ConversionPathPort", { useClass: ConversionPathGateway });
container.register("AccountRegistrationPort", { useClass: AccountRegistrationGateway });
container.register("CaptchaPort", { useClass: CaptchaGateway });
container.register("CaptchaTokenPort", { useClass: CaptchaTokenGateway });
container.register("ReferralPort", { useClass: ReferralGateway });
container.register("ContactEmailModificationPort", { useClass: ContactEmailModificationGateway });
container.register("ContactEmailPort", { useClass: ContactEmailGateway });

const bugsnagPluginReact = bugsnag.getPlugin("react");
if (!bugsnagPluginReact) {
  throw new Error("@bugsnag/plugin-react is not installed");
}

const ErrorBoundary = bugsnagPluginReact.createErrorBoundary(React);

export const AppConfig: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorView}>
      <ToastProvider>
        <LeaveConfirmProvider>{children}</LeaveConfirmProvider>
      </ToastProvider>
    </ErrorBoundary>
  );
};
