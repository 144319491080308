import { css } from "@emotion/react";

import { Flex } from "@/ui/layout/Flex";
import { Layout } from "@/ui/layout/Layout";
import { PageRoot } from "@/ui/layout/PageRoot";

const ErrorView: React.FC = () => {
  return (
    <PageRoot title="問題が発生しました">
      <Layout>
        <Flex justifyContent="center">
          <Flex
            style={css`
              padding-top: 40px;
              padding-bottom: 80px;
            `}
            flexDirection="column"
            alignItems="start"
            gap={24}>
            <h1>問題が発生しました</h1>
            <p>一時的にアクセスができない状態です。時間を置いて再度お試しください。 </p>
          </Flex>
        </Flex>
      </Layout>
    </PageRoot>
  );
};

export default ErrorView;
