import axios from "axios";
import { injectable } from "tsyringe";

import { Token } from "@/lib/domain";
import { MailAddressUpdateStatus } from "@/lib/domain/MailAddressUpdateStatus";
import { NewspicksExpertApi } from "@/lib/driver";
import { ContactEmailPort } from "@/lib/port/ContactEmailPort";

@injectable()
export class ContactEmailGateway implements ContactEmailPort {
  constructor(readonly newspicksExpertApi: NewspicksExpertApi) {}
  async update(token: Token): Promise<MailAddressUpdateStatus> {
    try {
      const res = await this.newspicksExpertApi.updateContactEmail(token.value);
      return MailAddressUpdateStatus.fromStatusCode(res.status);
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        return MailAddressUpdateStatus.fromStatusCode(e.response.status);
      }
      throw e;
    }
  }
}
