import { injectable } from "tsyringe";

@injectable()
export class SpecializedDomains {
  getAll(): Array<{ id: string; name: string }> {
    return [
      { id: "management", name: "経営" },
      { id: "business-development_planning_marketing", name: "事業開発・企画・マーケティング" },
      { id: "administration", name: "管理" },
      { id: "sales", name: "営業" },
      { id: "customer-service_cs", name: "接客・CS" },
      { id: "overseas", name: "海外" },
      { id: "consultant", name: "コンサルタント" },
      { id: "it_engineer", name: "IT・エンジニア" },
      { id: "designer", name: "デザイナー" },
      { id: "technology_development_manufacturing", name: "技術・開発・製造" },
      { id: "financial-specialties", name: "金融専門職" },
      { id: "real-estate-professional", name: "不動産専門職" },
      { id: "medical-specialties", name: "メディカル専門職" },
      { id: "licentiate", name: "士業" },
      { id: "other", name: "その他" },
    ];
  }
}
