import { injectable } from "tsyringe";

@injectable()
export class Industries {
  getAll(): Array<{ id: string; name: string }> {
    return [
      { id: "construction_real-estate", name: "建設・不動産" },
      { id: "resources_energy", name: "資源・エネルギー" },
      { id: "material_processed-material", name: "素材・素材加工品" },
      { id: "mechanical_electrical-products", name: "機械・電気製品" },
      { id: "transport-machinery", name: "輸送機械" },
      { id: "food_daily-necessities", name: "食料・生活用品" },
      { id: "medicine_bio", name: "医薬・バイオ" },
      { id: "intermediate-distribution", name: "中間流通" },
      { id: "retail", name: "小売" },
      { id: "eating-out_prepared-meals", name: "外食・中食" },
      { id: "finance", name: "金融" },
      { id: "transportation-services", name: "運輸サービス" },
      { id: "advertising_information-communication-services", name: "広告・情報通信サービス" },
      { id: "consumer-services", name: "消費者サービス" },
      { id: "corporate-services", name: "法人サービス" },
      { id: "public-service", name: "公共サービス" },
      { id: "other", name: "その他" },
    ];
  }
}
