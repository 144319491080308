import { TwoChoiceQuestion } from "@/assets/data/complianceTraning/types";

export const DEV_TWO_CHOICE_QUESTIONS: TwoChoiceQuestion[] = [
  {
    title: "次の内容において、Aの対応は適切といえるか。",
    question: `
    エキスパート登録者であるAは、相談者からインタビューを受けている最中に、相談者のクライアントが自身の所属企業の競合企業であり、自身の回答が競合企業に提供される可能性があることに気づいた。そのため、その旨を申告し、インタビューを中止にした。
    `,
    correctAnswer: "適切である",
    explanation: `**正しい解答の解説はこちらです。正しい解答の解説はこちらです。正しい解答の解説はこちらです。**`,
  },
  {
    title: "次の内容において、適切か不適切か答えよ。",
    question: `
    この問題の答えは「適切ではない」だよ。
    `,
    correctAnswer: "適切ではない",
    explanation: `正しくない解答の解説はこちらです。正しくない解答の解説はこちらです。正しくない解答の解説はこちらです。`,
  },
];
